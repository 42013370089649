import { MenuFoldOutlined,DownOutlined, UpOutlined } from "@ant-design/icons";

import { Col, Drawer, Menu, Row } from "antd";
import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo.webp";
import Cart from "../../assets/images/cart.webp";
import login from "../../assets/images/login.webp";
import callicon from "../../assets/images/call-13.webp";

import Container from "../../components/container/Container";
import { removeFromLocalStorage, getFromLocalStorage } from "../../utils/helperFunctions";
import ChatTalk from "../../widgets/chatTalk/ChatTalk";
import './header.css';
import Cookies from 'js-cookie';
import bumberger from "../../assets/humbergertool.webp";
import { Input, Spin } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { CloseOutlined } from "@ant-design/icons";




const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [userDataseller, setUserDataseller] = useState({});
  const [current, setCurrent] = useState("/");
  const [userData, setUserData] = useState({
    email: getFromLocalStorage("Email"),
    id: getFromLocalStorage("Id"),
    pwd:"",
    name:"",
  });
  const userId = getFromLocalStorage("Id");
  const email = Cookies.get('Email_guest');
  const email1 = getFromLocalStorage("Email");
  const name = getFromLocalStorage("Name");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [categories, setCategories] = useState([]);
  const [customTShirtOpen, setCustomTShirtOpen] = useState(false); // State to toggle icon
  const [searchText, setSearchText] = useState("");
const [searchResults, setSearchResults] = useState([]);
const [loading, setLoading] = useState(false);

const [searchOpen, setSearchOpen] = useState(false);


const searchRef = useRef(null); // Create ref for search container

  // Manage cart length with state
  const [cartLength, setCartLength] = useState(parseInt(localStorage.getItem('cartLength') || 0));

  useEffect(() => {
    fetch("https://backend.inkox.com/api/product-sub-categories")
      .then(response => response.json())
      .then(data => {
        // Group subcategories by category
        const categoryMap = {};
        data.forEach(subcategory => {
          const categoryId = subcategory.category.id;
          if (!categoryMap[categoryId]) {
            categoryMap[categoryId] = {
              id: categoryId,
              name: subcategory.category.name,
              icon: subcategory.category.icon_path,
              subcategories: [],
            };
          }
          categoryMap[categoryId].subcategories.push(subcategory);
        });

        // Convert to array and update state
        setCategories(Object.values(categoryMap));
      })
      .catch(error => console.error("Error fetching subcategories:", error));
  }, []);
  const toggleDropdown = () => {
    setIsDropdownOpen((prevState) => !prevState);
  }

  const handleSearch = (value) => {
    setSearchText(value);
    
    if (!value.trim()) { 
      setSearchResults([]); // If input is empty, clear results
      return;
    }
  
    if (value.length > 2) {  // Fetch results only if input is longer than 2 characters
      setLoading(true);
      fetch(`https://backend.inkox.com/api/product?page=1&per_page=10&name=${value}`)
        .then(response => response.json())
        .then(data => {
          if (data.data && data.data.length > 0) {
            setSearchResults(data.data);
          } else {
            setSearchResults([]);
          }
          setLoading(false);
        })
        .catch(error => {
          console.error("Error fetching search results:", error);
          setLoading(false);
        });
    } else {
      setSearchResults([]);
    }
  };
  
  const handleProductClick = () => {
    setSearchResults([]); // Close the dropdown when clicking a product
    setSearchText(""); // Optionally clear search input
  };
  
  const handleSearchToggle = () => {
    setSearchOpen(!searchOpen);
  };
  
  // Fetch cart data and update the cartLength state
  const fetchCartData = (emailToFetch) => {
    fetch(`https://backend.inkox.com/api/checkouts/email/${emailToFetch}`)
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data) && data.length > 0) {
          setCartLength(data.length); // Update cartLength state
          localStorage.setItem('cartLength', data.length); // Update localStorage
        } else if (data && typeof data === 'object' && Object.keys(data).length > 0) {
          setCartLength(1); // Handle the case where a single object is returned
          localStorage.setItem('cartLength', 1);
        } else {
          setCartLength(0); // Set to 0 if no items found
          localStorage.setItem('cartLength', 0);
        }
      })
      .catch((error) => {
        console.error('Error fetching cart data:', error);
        setCartLength(0); // Set to 0 on error
        localStorage.setItem('cartLength', 0);
      });
  };

  // Fetch cart data on component load based on email
  useEffect(() => {
    if (email) {
      fetchCartData(email);
    } else if (email1) {
      fetchCartData(email1);
    }
  }, [email, email1]);
 
  // Static menu items
  const staticItems = [
    { label: "Products", key: "products" },
    { label: "Help Center", key: "help-center" },
    { label: "Blogs", key: "blogs" },

    // {
    //   label: "Login",
    //   key: "login",
    //   children: [
    //     { label: "Update Profile", key: "update-profile" },
    //     { label: "Update Password", key: "update-password" },
    //     { label: "Logout", key: "logout" },
    //   ],
    // },
    {
      label: "DESIGN NOW",
      key: "designer?id=150&pid=73&colorId=700005",
      className: "design-now-btn",
      url: "http://localhost:3000/designer?id=24&pid=73&colorId=700007",
    },
  ];

  // Generate dynamic category menu items
  const categoryItems = categories.map(category => ({
    label: (
      <a 
        href={`/products/${category.name.replace(/\s+/g, "-")}/${category.id}`} 
        onClick={(e) => e.stopPropagation()}
       
        rel="noopener noreferrer"
      >
        {category.name}
      </a>
    ),
    key: `category-${category.id}`,
    children: category.subcategories.map(subcategory => ({
      label: (
        <a 
          href={`/products/${category.name.replace(/\s+/g, "-")}/${category.id}/${subcategory.name.replace(/\s+/g, "-")}/${subcategory.id}`} 
          
          rel="noopener noreferrer"
        >
          {subcategory.name}
        </a>
      ),
      key: `products/${category.name.replace(/\s+/g, "-")}/${category.id}/${subcategory.name.replace(/\s+/g, "-")}/${subcategory.id}`,
    })),
    onClick: () => window.location.replace(`/products/${category.name.replace(/\s+/g, "-")}/${category.id}`), // Replaces the URL instead of adding to history
  }));
  
  
  // Combine static and dynamic items
  const items = [

    {
      label: "Custom Apparel",
      key: "custom-tshirt",
      children: categoryItems,
    },
    ...staticItems,
  ];
  useEffect(() => {
    if (userId && !userData.email) {
      fetch(`https://backend.inkox.com/api/user_login/${userId}`)
        .then((response) => response.json())
        
        .then((data) => {
          localStorage.setItem("Email", data.email);
          localStorage.setItem("Name", data.name);
          console.log("userDatatwo",data);


          setUserData(data);
        })

        .catch((error) => console.error("Error fetching user data:", error));
    }
  }, [userId, userData.email]);

  useEffect(() => {
  
      fetch(`https://backend.inkox.com/api/user_login/${userId}`)
        .then((response) => response.json())
        
        .then((data) => {
          // localStorage.setItem("Email", data.email);
          // localStorage.setItem("Name", data.name);
          // console.log("userDatatwo",data);


          setUserDataseller(data);
        })

        .catch((error) => console.error("Error fetching user data:", error));

  }, []);

console.log("userDataseller",userDataseller);

  useEffect(() => {
    if (location?.pathname) {
      let route = location?.pathname?.split("/")?.[1];
      setCurrent(route);
    }
  }, [location?.pathname]);

  const onClick = (e) => {
    if (e.key === "logout") {
      handleLogout();
    } else if (e.key === "login") {
      navigate("/login");
    } else {
      navigate(`/${e.key}`);
      setCurrent(e.key);
    }
  };

  const onClose = () => {
    setOpen(false);
  };

  const handleSidebar = () => {
    setOpen(true);
  };

  const handleLogout = () => {
    removeFromLocalStorage("Id");
    removeFromLocalStorage("Email");
    removeFromLocalStorage("APP_TOKEN");
    removeFromLocalStorage("Name");
    localStorage.clear();
    setUserData(null);
    navigate("/login");
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setSearchResults([]); // Close the dropdown if clicking outside
      }
    };
  
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  

    console.log("name", name);

  return (
    <header className="header">
      <div className="" style={{
          backgroundColor: "#f5f5f5",

      }}>
      <div
        className="container top-nav"
        style={{
          backgroundColor: "#f5f5f5",
    
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ flex: 1 }}>
          <span className="tagline">
            Custom T-shirts & Promotional Products, Fast & Free Shipping, and
            All-Inclusive Pricing



          </span>


        </div>

        <div style={{ display: "flex", alignItems: "center", gap: "15px" }} >
        <span className="search-bar-container" style={{ position: "relative", marginLeft: "20px" }}>
          {/* <Input
    placeholder="Search for products"
    prefix={<SearchOutlined />}
    value={searchText}
    onChange={(e) => handleSearch(e.target.value)}
    style={{ width: "300px", padding: "8px", borderRadius: "5px" }}
  /> */}
   <SearchOutlined 
    className="mobile-search-icon"
    onClick={handleSearchToggle} // Opens the search drawer on mobile
    style={{
      fontSize: "20px",
      cursor: "pointer",
      padding:"8px",
      border:"1px solid #d8d6d6",
  
    }}
  />
  {loading && <Spin size="small" />}
</span>
          <Link to={`/cart`}>
            <button  className="icon-headers icon-headersemail"
              style={{
                padding: "6px 0px",
                backgroundColor: "transparent",
                border: "none",
                color: "#1f1f2b",
                fontSize: "16px",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                src={Cart}
                alt="cart"
                style={{ width: "20px", height: "20px", marginRight: "8px" }}
              />
              Cart ({cartLength})
            </button>
          </Link>
          {/* <div id="google_translate_element"></div> */}


          {/* If no userData, show the login button */}
          {!userData?.email ? (
            <Link to="/login">
              <button className="icon-headers icon-headersemail "
                style={{
                  padding: "6px 0px",
                  backgroundColor: "transparent",
                                    border: "none",
                  color: "#1f1f2b",
                  fontSize: "16px",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={login}
                  alt="login"
                  style={{ width: "20px", height: "20px", marginRight: "8px" }}
                />
                Login
              </button>
            </Link>
          ) : (
            <div style={{ display: "flex", alignItems: "center", position: "relative" }}>
            {/* Display user's email */}
            <span
              style={{
                marginRight: "15px",
                fontSize: "12px",
                cursor: "pointer",
              }}
              onClick={toggleDropdown}
            >
              Welcome {name}
            </span>
          
            {isDropdownOpen && (
              <div
              className="main-login-signup"
              
                style={{
                  position: "absolute",
                  top: "100%", // Positions it below the "Welcome" text
                  left: 0, // Aligns to the left
                  border: "1px solid #ccc",
                  padding: "10px",
                  backgroundColor: "#fff",
                  zIndex: 1000,
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Adds shadow for better visibility
                  minWidth: "120px", // Ensures a readable width
                }}
              >
                {/* <Link to="/products" style={{ padding: "8px", cursor: "pointer" }}>Dashboard</Link> */}
                {userData?.id && (
                  <>
                  <p>
  <a
    href={`https://dashboard.inkox.com/auth/login?id=${userDataseller.id}&name=${encodeURIComponent(userDataseller.name)}&firstName=${encodeURIComponent(userDataseller.name)}&lastName=${encodeURIComponent(userDataseller.name)}&pwd=${encodeURIComponent(userDataseller.pwd)}&token=${encodeURIComponent(getFromLocalStorage("APP_TOKEN"))}&role=seller`}
    style={{ cursor: "pointer", display: 'inline-block' }}
    target="_blank" // Optional to open in new tab
    rel="noopener noreferrer"
  >
    Dashboard
  </a>
  </p>
  <p>
  <Link to="/accounts">My Account</Link>
  </p>
  </>
)}

              </div>
            )}
          
            <button
              onClick={handleLogout}
              style={{
                padding: "6px 10px",
                border: "none",
                background: "transparent",
                color: "#1f1f2b",
                fontSize: "12px",
                cursor: "pointer",
              }}
            >
              Logout
            </button>
          </div>
          
          )}

<Link to="/login">
              <button className="icon-headers formobileonly"
                style={{
                  padding: "6px 0px",
                  backgroundColor: "transparent",
                                    border: "none",
                  color: "#1f1f2b",
                  fontSize: "16px",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={callicon}
                  alt="call"
                  style={{ width: "auto", height: "20px", marginRight: "8px" }}
                />
                Help
              </button>
            </Link>
        </div>
      </div>
      </div>
      <Container className="container-header-main">
        <Row gutter={[40, 20]} justify={"space-between"} className="row-wesite-header" align={"middle"}  style={{ flexFlow: 'row',  padding:'12px 0px'}} // Add flex-flow property here
        >
     <Col md={2} className="mobile-menu" style={{ textAlign: "right" }}>
    <img 
        src={bumberger} 
        alt="Menu" 
        className="sideBarIcon" 
        style={{ cursor: "pointer" }} 
        onClick={handleSidebar} 
    />
</Col>

          <Col xs={10} sm={12} md={12} lg={12} xl={4} className="logo-colomn-mobile">
            <Link to={"/"} className="brand-logo">
              <img src={logo} alt="logo" />
            </Link>
          </Col>


          <Col xs={12} sm={10} md={10} lg={10} xl={0} className="">
          <div style={{ display: "flex", justifyContent:"right", alignItems: "center", gap: "5px" }}>
          <Link to={`/cart`}>
            <button  className="icon-headerstwo"
              style={{
                padding: "6px 0px",
                backgroundColor: "",
                border: "none",
                color: "#1f1f2b",
                fontSize: "12px",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
              }}
            >
              <img
                src={Cart}
                alt="cart"
                style={{ width: "auto", height: "20px", marginRight: "8px" }}
              />
              Cart ({cartLength})
            </button>
          </Link>
          <div id="google_translate_element"></div>

          {/* If no userData, show the login button */}
          {!userData?.email ? (
            <Link to="/login">
              <button className="icon-headerstwo"
                style={{
                  padding: "6px 0px",
                  backgroundColor: "",
                  border: "none",
                  color: "#1f1f2b",
                  fontSize: "12px",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={login}
                  alt="login"
                  style={{ width: "auto", height: "20px", marginRight: "8px" }}
                />
                Login
              </button>
            </Link>
          ) : (
            <div style={{ display: "flex", alignItems: "center" }} className="header-hide-website">
              {/* Display user's email */}
              <span style={{ marginRight: "15px", fontSize: "12px" }}>
                {userData.email}
              </span>
              <button
                onClick={handleLogout}
                style={{
                  padding: "6px 0px",
                  backgroundColor: "",
                  border: "none",
                  color: "#1f1f2b",
                  fontSize: "12px",
                  cursor: "pointer",
                }}
              >
                Logout
              </button>
            </div>
          )}
        </div>
          </Col>
          <Col md={14} className="desktop-menu desktopmenu-two">
            <Menu
              onClick={onClick}
              selectedKeys={[current]}
              mode="horizontal"
              items={items}
            />
          </Col>

          <Col md={6} className="desktop-menu">
            <ChatTalk />
          </Col>
          
        </Row>
      </Container>
      <Drawer
        placement="left"
        onClose={onClose}
        open={open}
        className="header-sidebar"
      >
        <Menu
          onClick={onClick}
          selectedKeys={[current]}
          mode="inline"
          items={items}
        />
      </Drawer>
    
      <Drawer
  placement="right"
  onClose={() => setSearchOpen(false)}
  open={searchOpen}
  className="search-sidebar"
>
  <div className="search-results-container">
    <Input
      placeholder="Search for products"
      prefix={<SearchOutlined />}
      value={searchText}
      onChange={(e) => handleSearch(e.target.value)}
      style={{ width: "100%", padding: "10px", borderRadius: "5px", marginBottom: "10px" }}
    />

    {loading ? (
      <p style={{ textAlign: "center", color: "#666" }}>Loading products...</p>
    ) : searchResults.length > 0 ? (
      searchResults.map((product) => {
        const firstImage = product.productImages ? product.productImages.split(",")[0].trim() : null;
        const productUrl = `/${product.name.replace(/\s+/g, "-").toLowerCase()}/${product.id}`;

        return (
          <div className="image-preview-searchbar-main" key={product.id}>
            <div className="image-preview-searchbar">
              <Link 
                to={productUrl} 
                className="search-result-item" 
                onClick={() => {
                  handleProductClick();
                  setSearchOpen(false); // Close search sidebar after selection
                }}
              >
                {firstImage ? (
                  <img 
                    src={`https://backend.inkox.com/${firstImage}`} 
                    alt={product.name} 
                    className="search-product-image" 
                  />
                ) : (
                  <p style={{ textAlign: "center", color: "#666" }}>No image available</p>
                )}
                <div className="search-product-info">
                  <span className="search-product-name">{product.name}</span>
                </div>
              </Link>
            </div>
          </div>
        );
      })
    ) : (
      <p style={{ textAlign: "center", color: "#666" }}>No matching product found</p>
    )}
  </div>
</Drawer>






    </header>
  );
};

export default Header;
