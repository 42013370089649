import React from "react";
import { Link } from "react-router-dom";
import fbIcon from "../../assets/images/fb.png";
import twitterIcon from "../../assets/images/twitter.png";
import instaIcon from "../../assets/images/insta.png";
import linkedinIcon from "../../assets/images/linkedin.png";
import youtubeIcon from "../../assets/images/youtube.png";
const SocialIcons = ({ data }) => {
  const iconCheckHandle = (v) => {
    if (v.includes("facebook")) {
      return "facebook";
    }
    if (v.includes("youtube")) {
      return "youtube";
    }
    if (v.includes("twitter")) {
      return "twitter";
    }
    if (v.includes("linkedin")) {
      return "linkedin";
    }
    if (v.includes("instagram")) {
      return "instagram";
    }
  };
  const renderIcon = (key) => {
    const iconName = iconCheckHandle(key);
    switch (iconName) {
      case "facebook":
        return <img src={fbIcon} alt="facebook-icon" />;
      case "youtube":
        return <img src={youtubeIcon} alt="youtube-icon" />;
 
      case "instagram":
        return <img src={instaIcon} alt="instagram-icon" />;
      default:
        break;
    }
  };
  return (
    <div className="socialIcons">
      {data?.map((item, index) => {
        return (
          <Link target="_blank" key={index} to={item || "#"}>
            {renderIcon(item)}
          </Link>
        );
      })}
    </div>
  );
};

export default SocialIcons;
