import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./styles/index.scss";
import { store } from "./redux/store";
import { Provider } from "react-redux";
import "./styles/font.css";
import TagManager from "react-gtm-module";

// Initialize Google Tag Manager
const tagManagerArgs = {
  gtmId: "GTM-PXNLVW5X", // Replace with your actual GTM ID
};


TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  // </React.StrictMode>
);
