import React from "react";
import LoginForm from "../components/forms/loginForm/LoginForm";
import LayoutWrapper from "../layout/layoutWrapper/LayoutWrapper";

const Login = () => {
  return (
    <LayoutWrapper>

    <div className="login">
      <LoginForm />
    </div>
    </LayoutWrapper>

  );
};

export default Login;
