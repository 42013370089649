import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import LayoutWrapper from '../layout/layoutWrapper/LayoutWrapper';
import './Blog.css';

const Loader = () => (
    <div className="loader-container loader-main-index">
      <div className="spinner"></div>
    </div>
  );

const BlogDetail = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [blogPost, setBlogPost] = useState(null);
    const [categoryName, setCategoryName] = useState('');

    useEffect(() => {
        // Fetch the blog details by ID
        fetch(`https://backend.inkox.com/api/blogs`)
            .then(response => response.json())
            .then(data => {
                const foundBlog = data.find(blog => blog.id === parseInt(id));
                setBlogPost(foundBlog);
            })
            .catch(error => console.error("Error fetching blog details:", error));

        // Fetch blog categories and match category name
        fetch(`https://backend.inkox.com/api/blogs-catg`)
            .then(response => response.json())
            .then(data => {
                if (blogPost) {
                    const category = data.find(cat => cat.id === blogPost.catg_id);
                    setCategoryName(category ? category.catg_name : "Uncategorized");
                }
            })
            .catch(error => console.error("Error fetching categories:", error));
    }, [id, blogPost]); // Depend on `id` and `blogPost` to refetch data properly

    if (!blogPost) {
        return <LayoutWrapper><h2><Loader /></h2></LayoutWrapper>;
    }

    return (
        <LayoutWrapper>
            <div className='container'>
                <div className="blog-detail-container">
                    {/* Breadcrumbs & Back Button */}
                    <div className="blog-header">
                        <p className="breadcrumb" onClick={() => navigate('/')}>Blog / {categoryName} / {blogPost.title}</p>
                        {/* <button className="back-button" onClick={() => navigate('/blogs')}>Back</button> */}
                    </div>
                    
                    {/* Blog Content */}
                    <div className="blog-main-content">
                        <div className='blog-imagedetail'>
                            <img 
                                src={`https://backend.inkox.com/storage/${blogPost.image}`} 
                                alt={blogPost.title} 
                                className="blog-detail-image" 
                            />
                        </div>
                    </div>
                    
                    <div className="blog-content">
                        <h1 className="blog-detail-description">{blogPost.title}</h1>
                        <p className="blog-detail-descriptionpara" dangerouslySetInnerHTML={{ __html: blogPost.description }}></p>
                        <hr />
                        
                        {/* Blog Meta Information */}
                        <div className="blog-meta">
                            <div className="blog-author-details">
                                <h3 className="blog-author">Author ID: {blogPost.author_id}</h3>
                                <p className="blog-date">{new Date(blogPost.date).toDateString()}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </LayoutWrapper>
    );
};

export default BlogDetail;
