import React from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom"; // Importing Link

import girlimagetwo from  "../../assets/images/girltwoimg.webp";
import shirtbanner from  "../../assets/images/banner.webp";


import girlimage from  "../../assets/images/12.webp";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Banner = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    fade: true, // Enable fade transition
  };

  return (
    <div className="banner-new">
      {/* <Slider {...settings}>
        <div>
          <img src={banner1} alt="Banner 1" style={{ width: "100%", height: "auto" }} />
        </div>
        <div>
          <img src={banner2} alt="Banner 2" style={{ width: "100%", height: "auto" }} />
        </div>
        <div>
          <img src={banner3} alt="Banner 3" style={{ width: "100%", height: "auto" }} />
        </div>
      </Slider> */}

      <div className="bannermain-div">
        <div className="bannerinner-div container">

          <div className="col-md-inner-one">
            <div className="side-sectoion-banner">

            <h1><span className="design-your">Design Your</span> <span className="perfect-look">Perfect Look</span></h1>
            <hr className="line-banner"></hr>
            <p>Create custom apparel in minutes—start designing now</p>
            <div className="button-banner">           
            <Link to="designer?id=150&pid=94&colorId=700005"> <button>DESIGN NOW</button></Link>
            <Link to="Products"> <button className="shopproduct-banner">SHOP PRODUCT</button></Link>
            </div>
 
            </div>


          </div>

          <div className="col-md-inner-two">

<img src={shirtbanner} alt="girlimagetwo"/>
</div>
       





        </div>
      </div>
    </div>
  );
};

export default Banner;
