import React, { useState, useContext, useEffect, useId, useRef  } from 'react';
import './style.css';
import CanvasComponentOne from './components/CanvasComponentOne';
import CanvasComponentTwo from './components/CanvasComponentTwo';
import CanvasComponentThree from './components/CanvasComponentThree';
import AddTextComponent from './components/AddTextComponent';
import AddClipartComponent from './components/AddClipartComponent';
import UploadImageComponent from './components/UploadImageComponent';
import ConstantSidebar from './components/ConstantSidebar';
import ObjectColorComponent from './components/ObjectColorComponent';
// import tshirtDesign from './json/tshirtDesign.json';
import productColors from './json/productcolors.json';
import { ColorProvider } from './context/ColorContext';
import ProductColor from './components/ProductColor';
import AiSidebar from './components/aisidebar';
import { ColorContext } from './context/ColorContext';

import PriceSidebar from './components/PriceSidebar';
import envelope from './assets/Vector (3).png';
import save from './assets/Vector (2).png';
import tabshirt from './assets/icontabmobile.png';
import tabopen from './assets/tabopen.webp';
import tabclose from './assets/tabclose.webp';
import { fabric } from 'fabric';
import Path from './components/path-warp';
import HistoryDisplay from './components/HistoryDisplay'; // Import the HistoryDisplay component
import ExportButton from './components/ExportButton';
import close from './assets/close.png';
import iicon from './assets/iicon.png';

import opentype from 'opentype.js';
import DetailImageBox from './components/DetailImageBox';
import { BrowserRouter as Router, Route, Routes, useParams } from 'react-router-dom'; // Use Routes instead of Switch
import PreviewPage from './components/PreviewPage'; // Import the PreviewPage component
import MainCategoryPage from './components/MainCategoryPage'; // Import the PreviewPage component

import SubcategoryPage from './components/SubcategoryPage'; // Import the PreviewPage component
import DetailClipartBox from './components/DetailClipartBox';
import LayoutWrapper from '../layout/layoutWrapper/LayoutWrapper';
import HeaderTool from '../layout/header/HeaderTool';
import Footer from '../layout/footer/Footer';
import { colorHexMap } from './colorlibrarygarments';
import ChangeProductModal from './components/ChangeProductModal'; // Import your modal
import CanvasComponentFour from './components/CanvasComponentFour';





const svgAddText = `<svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path class="st0" d="M19.9507 29.4259H23.1969L24.8201 24.7594H32.2256L33.8488 29.4259H36.9936L30.0953 11.1657H26.849L19.9507 29.4259ZM25.7331 22.1218L28.3707 14.5134H28.5736L31.2112 22.1218H25.7331ZM16.2986 36.5271C15.1827 36.5271 14.2278 36.1301 13.4338 35.3362C12.6398 34.5422 12.2421 33.5866 12.2408 32.4693V8.12229C12.2408 7.00638 12.6385 6.05144 13.4338 5.25746C14.2291 4.46347 15.1841 4.06581 16.2986 4.06445H40.6456C41.7615 4.06445 42.7172 4.46212 43.5125 5.25746C44.3078 6.05279 44.7048 7.00774 44.7035 8.12229V32.4693C44.7035 33.5852 44.3065 34.5408 43.5125 35.3362C42.7185 36.1315 41.7629 36.5285 40.6456 36.5271H16.2986ZM8.18296 44.6428C7.06705 44.6428 6.11211 44.2458 5.31813 43.4518C4.52414 42.6579 4.12647 41.7022 4.12512 40.585V12.1801H8.18296V40.585H36.5878V44.6428H8.18296Z" fill="#ADB8CC"/>
  </svg>`;

const svgClipArt = `
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
     viewBox="0 0 388.12 392.63" style="enable-background:new 0 0 388.12 392.63;" xml:space="preserve">
  <style type="text/css">
    .st0{fill-rule:evenodd;clip-rule:evenodd;fill:#4D5E80;}
  </style>
  <path class="st0" d="M31.1,0h325.93c17.09,0,31.1,13.97,31.1,31.1v330.43c0,17.09-14,31.1-31.1,31.1H31.1
    c-17.12,0-31.1-14-31.1-31.1V31.1C0,13.97,13.97,0,31.1,0L31.1,0z M119.2,211.04l-75.2,95.53h288.4l-94.48-116.11l-59.05,90.11
    L119.2,211.04z"/>
  </svg>`;

const svgUploadImage = `
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
     viewBox="0 0 422.76 275.84" style="enable-background:new 0 0 422.76 275.84;" xml:space="preserve">
  <style type="text/css">
    .st0{fill-rule:evenodd;clip-rule:evenodd;fill:#4D5E80;}
  </style>
  <path class="st0" d="M341.63,275.84c44.82,0,81.13-36.31,81.13-81.13c0-44.79-36.31-81.1-81.13-81.1c-3.34,0-6.63,0.2-9.89,0.6
    C328.51,50.6,275.9,0,211.49,0c-50.54,0-93.8,31.12-111.66,75.23C44.65,75.49,0,120.3,0,175.55c0,55.39,44.9,100.29,100.29,100.29
    H341.6H341.63z M211.38,235.39h-31.86v-81.44h-51.93l83.79-86.74l83.79,86.74h-51.93v81.44H211.38z"/>
  </svg>`;

const svgProductColor = `
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
     viewBox="0 0 294.87 370.27" style="enable-background:new 0 0 294.87 370.27;" xml:space="preserve">
  <style type="text/css">
    .st0{fill-rule:evenodd;clip-rule:evenodd;fill:#4D5E80;}
  </style>
  <path class="st0" d="M14.97,290.18C-33.13,200.3,40.99,70.19,147.43,0C253.88,70.19,328,200.3,279.9,290.18
    C222.75,396.96,72.12,396.96,14.97,290.18L14.97,290.18z M159.08,331.06c-9.89,0.31-18.17-7.46-18.48-17.38
    c-0.31-9.89,7.46-18.17,17.38-18.48c0.11,0,40.05-0.91,51.14-49.75c2.18-9.69,11.79-15.76,21.46-13.58s15.73,11.76,13.58,21.43
    C226.86,329.44,159.28,331.06,159.08,331.06L159.08,331.06z"/>
  </svg>`;

const extractColorsFromObject = (obj, includeStroke = true) => {
  const colors = new Set();

  if (obj.type === 'group') {
    obj.getObjects().forEach((childObj) => {
      if (childObj.fill) {
        colors.add(childObj.fill);
      }
      if (includeStroke && childObj.stroke) {
        colors.add(childObj.stroke);
      }
    });
  } else if (obj.type === 'image') {
    if (obj.singleColorMode) {
      // Use single color mode palette
      const singleColor = obj.singleColor;
      colors.add(`rgb(${singleColor.r}, ${singleColor.g}, ${singleColor.b})`);
    } else if (obj.makeColorMode) {
      // Use make one color mode palette
      const makeColor = obj.makeColor;
      colors.add(`rgb(${makeColor.r}, ${makeColor.g}, ${makeColor.b})`);
    } else if (obj.paletteColors) {
      // Normal palette colors
      obj.paletteColors.forEach((color) => {
        colors.add(`rgb(${color[2]}, ${color[1]}, ${color[0]})`);
      });

      // If bgRemove is active, remove one color from the palette
      if (obj.bgRemove && obj.paletteColors.length > 0) {
        const dominantColor = `rgb(${obj.paletteColors[0][2]}, ${obj.paletteColors[0][1]}, ${obj.paletteColors[0][0]})`;
        if (colors.has(dominantColor)) {
          colors.delete(dominantColor);
        }
      }
    }
  } else if (obj.fill) {
    colors.add(obj.fill);
  }

  if (includeStroke && obj.stroke) {
    colors.add(obj.stroke); // Include stroke color for price calculation, not for UI
  }

  return Array.from(colors);
};

const Loader = () => (
  <div className="loader-container loader-main-index">
    <div className="spinner"></div>
  </div>
);


const View = () => {
  const [canvasOne, setCanvasOne] = useState(null);
  const [canvasTwo, setCanvasTwo] = useState(null);
  const [canvasThree, setCanvasThree] = useState(null); // Add state for the third canvas
  const [canvasFour, setCanvasFour] = useState(null);
  const [activeSidebar, setActiveSidebar] = useState('main');
  const [selectedObject, setSelectedObject] = useState(null);
  const [selectedObjectType, setSelectedObjectType] = useState('');
  const [selectedObjectSize, setSelectedObjectSize] = useState({ width: 0, height: 0 });
  const [selectedObjectColors, setSelectedObjectColors] = useState([]);
  const [showColorPalette, setShowColorPalette] = useState(false);
  const [inkColors, setInkColors] = useState([]);
  const [originalColors, setOriginalColors] = useState(new Map());
  const [activeTab, setActiveTab] = useState('');
  const [selectedColor, setSelectedColor] = useState('');
  const [historyOne, setHistoryOne] = useState({ undo: [], redo: [] });
  const [historyTwo, setHistoryTwo] = useState({ undo: [], redo: [] });
  const [historyThree, setHistoryThree] = useState({ undo: [], redo: [] }); // Add history for the third canvas
  const [historyFour, setHistoryFour] = useState({ undo: [], redo: [] });
  const [quantity, setQuantity] = useState(0); // Add state for quantity
  const [priceDisplay, setPriceDisplay] = useState('Unavailable');
  const [isPriceSidebarVisible, setIsPriceSidebarVisible] = useState(false);
  const [selectedImageColors, setSelectedImageColors] = useState([]);
  const [isPng, setIsPng] = useState(false);
  const [quantities, setQuantities] = useState({});
  const [text, setText] = useState('');
  const [productName, setProductName] = useState('');
  const [email, setEmail] = useState('');
  const [designName, setDesignName] = useState('');
  const [zipCode, setZipCode] = useState("");
  const [selectedCategory, setSelectedCategory] = useState('Category1'); // Default to an initial category
  const [isLoadingImage, setIsLoadingImage] = useState(false);
  const [fonts, setFonts] = useState([]);
  const [colorsData, setColorsData] = useState([]);
  const [clipLocked, setClipLocked] = useState(false);
  const [clipartRotation, setClipartRotation] = useState(0);
  const [coutlineWidth, setCOutlineWidth] = useState(0);
  const [coutlineColor, setCOutlineColor] = useState("none");
  const [strokeColor, setStrokeColor] = useState('#000000');
  const [strokeWidth, setStrokeWidth] = useState(0);
  const uniqueId = useId();
  const [isTabsVisible, setIsTabsVisible] = useState(true);

  const [getValue, setGetValue] = useState([]);
  const [isOn, setIsOn] = useState(false);
  const [showEmbroideryBox, setShowEmbroideryBox] = useState(false); // State for toggle box visibility

  const [showEmbroideryText, setShowEmbroideryText] = useState(true); // State for the text bar visibility

  const [retailprice, setRetailprice] = useState([]);

  const [switchDisabled, setSwitchDisabled] = useState(false); // Manage switch bar disabled state
  
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [savedImages, setSavedImages] = useState([]); // Store images here


  const [isSwitchingTab, setIsSwitchingTab] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false); // Manage modal state
    const [imagecaptureFront, setImagecaptureFront] = useState();
    const [imagecaptureBack, setImagecaptureBack] = useState();
    const [imagecaptureRight, setImagecaptureRight] = useState();
    const [imagecaptureLeft, setImagecaptureLeft] = useState();


  
  
  const openModal = () => setIsModalOpen(true);

  

  // Function to close the modal
  const closeModal = () => setIsModalOpen(false);
   
  const queryString = window.location.search; // Gets the query string, e.g., "?id=ts-rn-hs-02"
  const params = new URLSearchParams(queryString); // This is a safer method to handle query strings
  const Canvasid = params.get('id'); // Extracts the value of 'id' from the query string
  const pid = params.get('pid');
  const colorId = params.get('colorId');
    const influencerid = params.get('influencerid');
    console.log("influencerid",influencerid);


  console.log(Canvasid);
  console.log(pid);
  console.log(colorId);
  const [selectedFont, setSelectedFont] = useState('https://inkox.com/fonts/Cheesecake.ttf'); // State for the selected font

  const [bend, setBend] = useState(100);
  const [horizontalDistort, setHorizontalDistort] = useState(0);
  const [verticalDistort, setVerticalDistort] = useState(0);
  const [warpType, setWarpType] = useState('');
  const [originalPath, setOriginalPath] = useState('');
  const [productData, setProductData] = useState(null);

  const [showApplyTransformationButton, setShowApplyTransformationButton] = useState(false);
  const [tshirtDesign, setTshirtDesign] = useState([]);
  const [productPrice, setProductPrice] = useState([]);
  const [productImage, setProductImage] = useState(null);
  const [designId, setDesignId] = useState('');
  const [spcolor, setSpcolor] = useState('');
 const  [numcolor, setNumColor] = useState(0);
 const { selectedColorName, setSelectedColorName } = useContext(ColorContext);
 const [colorname, setColorName] = useState();
 const [imageFile, setImageFile] = useState(null); // null initially, then File object


console.log("madsd",numcolor);

  const [previewImages, setPreviewImages] = useState({});


  const closePropertiesSidebar = () => {
    setActiveSidebar('main'); // This will close the properties sidebar by resetting it to the main sidebar
  };
  // console.log('selectedColorNamessss',selectedColorName);

  // const parts = tshirtDesign.parts; // Ensure parts is always an array
  const basePrice = parseInt(productPrice);
  console.log("-------TshirtDesign", tshirtDesign.parts);


console.log("imagecaptureFront",imagecaptureFront);
console.log("imagecaptureback",imagecaptureBack);
console.log("imagecaptureright",imagecaptureRight);
console.log("imagecaptureleft",imagecaptureLeft);
  
  console.log("-------basePrice", basePrice);
  const [canvasPrices, setCanvasPrices] = useState([0, 0, 0]);
  const [totalPrice, setTotalPrice] = useState(basePrice);
  const [colors, setColors] = useState([]);
  const [parts, setParts] = useState([]);

  const addToHistory = (obj, prevState, newState, action) => {
    if (!obj) return;

    let objectState = {
      id: obj.id,
      type: obj.type,
      clipartPath: obj.clipartPath, // Ensure clipartPath is included

      left: obj.left,
      top: obj.top,
      width: obj.width,
      height: obj.height,
      scaleX: obj.scaleX,
      scaleY: obj.scaleY,
      angle: obj.angle,
      fill: obj.fill,
      stroke: obj.stroke,
      opacity: obj.opacity,
      flipX: obj.flipX,
      flipY: obj.flipY,
      skewX: obj.skewX,
      skewY: obj.skewY,
      fontFamily: obj.fontFamily,
      fontPath: obj.fontPath,
      originalText: obj.originalText,
      textAlign: obj.textAlign,
      fontStyle: obj.fontStyle,
      lineHeight: obj.lineHeight,
      letterSpacing: obj.letterSpacing,
      underline: obj.underline,
      overline: obj.overline,
      linethrough: obj.linethrough,
      path: obj.path,
      src: obj.type === 'image' ? obj.getSrc() : undefined,
      originalPath: obj.originalPath,
      warpType: obj.warpType || '',
      bend: obj.bend !== undefined ? obj.bend : 0,
      distortH: obj.distortH !== undefined ? obj.distortH : 0,
      distortV: obj.distortV !== undefined ? obj.distortV : 0,
      imageFile: obj.imageFile || false,
      imageType: obj.imageType || '',
      paletteColors: obj.paletteColors || [],
      colorChanged: obj.colorChanged,
      session_id: obj.session_id,
      letterSpacing: obj.letterSpacing,
      stroke: obj.stroke, // Include stroke color
      strokeWidth: obj.strokeWidth, // Include stroke width
      paintFirst: "stroke", // Ensure stroke is painted first
      isFullColor: obj.isFullColor


    };

    // Handling group objects
    if (obj.type === 'group') {
      objectState.objects = obj._objects.map((subObj) => {
        return {
          id: subObj.id,
          type: subObj.type,
          left: subObj.left,
          top: subObj.top,
          width: subObj.width,
          height: subObj.height,
          scaleX: subObj.scaleX,
          scaleY: subObj.scaleY,
          angle: subObj.angle,
          fill: subObj.fill,
          stroke: subObj.stroke,
          opacity: subObj.opacity,
          flipX: subObj.flipX,
          flipY: subObj.flipY,
          skewX: subObj.skewX,
          skewY: subObj.skewY,
          fontFamily: subObj.fontFamily,
          fontPath: subObj.fontPath,
          originalText: subObj.originalText,
          textAlign: subObj.textAlign,
          fontStyle: subObj.fontStyle,
          lineHeight: subObj.lineHeight,
          letterSpacing: subObj.letterSpacing,
          underline: subObj.underline,
          overline: subObj.overline,
          linethrough: subObj.linethrough,
          path: subObj.path,
          src: subObj.type === 'image' ? subObj.getSrc() : undefined,
          originalPath: subObj.originalPath,
          warpType: subObj.warpType || '',
          bend: subObj.bend !== undefined ? subObj.bend : 0,
          distortH: subObj.distortH !== undefined ? subObj.distortH : 0,
          distortV: subObj.distortV !== undefined ? subObj.distortV : 0,
          imageFile: subObj.imageFile || false,
          imageType: subObj.imageType || '',
          paletteColors: subObj.paletteColors || [],
          colorChanged: subObj.colorChanged,
          session_id: subObj.session_id,
          bgRemove: subObj.bgRemove,
          dominant_color:subObj.dominant_color,
        };
      });
    }

    // Detailed logging for debugging
    console.log('Previous State:', prevState);
    console.log('New State:', newState);
    console.log('Object State:', objectState);

    const forceHistoryEntry = true;

    if (forceHistoryEntry || JSON.stringify(prevState) !== JSON.stringify(objectState)) {
      console.log('Adding to history: ', { prevState, objectState, action });

      const historyState = {
        objId: obj.id,
        prevState: { ...prevState },
        newState: { ...objectState },
        action
      };

      if (activeTab === 'Front') {
        setHistoryOne((prevHistory) => ({
          ...prevHistory,
          undo: [...prevHistory.undo, historyState],
          redo: obj.type !== 'image' ? [] : prevHistory.redo,
        }));
      } else if (activeTab === 'Back') {
        setHistoryTwo((prevHistory) => ({
          ...prevHistory,
          undo: [...prevHistory.undo, historyState],
          redo: obj.type !== 'image' ? [] : prevHistory.redo,
        }));
      } else if (activeTab === 'Right') {
        setHistoryThree((prevHistory) => ({
          ...prevHistory,
          undo: [...prevHistory.undo, historyState],
          redo: obj.type !== 'image' ? [] : prevHistory.redo,
        }));
      } else if (activeTab === 'Left') {
        setHistoryFour((prevHistory) => ({
          ...prevHistory,
          undo: [...prevHistory.undo, historyState],
          redo: obj.type !== 'image' ? [] : prevHistory.redo,
        }));
      }
    }  else {
      console.log('States are identical, not adding to history.');
    }
  }
console.log('selectedColorpassing',selectedColor)


useEffect(() => {
  if (parts.length > 0) {
    const activePart = parts[0]; // Assuming the first part determines the canvasType
    setActiveTab(activePart.name);

    // Determine default switch bar state based on canvasType
    if (activePart.canvasType === 'embroidery') {
      setIsOn(true); // Default to Embroidery
      setSwitchDisabled(true); // Disable the switch
      setShowEmbroideryBox(true); // Show the embroidery box
    } else if (activePart.canvasType === 'screenPrinting') {
      setIsOn(false); // Default to Screen Printing
      setSwitchDisabled(true); // Disable the switch
      setShowEmbroideryBox(false); // Hide the embroidery box
    } else if (activePart.canvasType === 'embroidery and screenPrinting') {
      setIsOn(false); // Default to Screen Printing
      setSwitchDisabled(false); // Enable the switch
      setShowEmbroideryBox(false); // Hide the embroidery box
    }
  }
}, [parts]);

const handleSidebarChange = (sidebar) => {
  // Deselect objects first to ensure immediate effect
  deselectAllObjects(); 

  // Batch state updates using functional updates to prevent delays
  setActiveSidebar((prev) => {
    setSelectedObjectType(sidebar);
    setShowApplyTransformationButton(sidebar === 'text');
    return sidebar; 
  });
};

  fabric.Object.prototype.clone = function (callback, propertiesToInclude) {
    if (callback) {
      const properties = ['originalPath','dominantColor',"dominant_color", 'warpType', 'bend', 'distortH', 'distortV', 'fontFamily', 'fontPath', 'originalText','letterSpacing','rotation',].concat(propertiesToInclude || []);
      const clone = fabric.util.object.clone(this.toObject(properties));
      fabric.util.enlivenObjects([clone], function (enlivenedObjects) {
        callback && callback(enlivenedObjects[0]);
      });
    }
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value); // Update the selected category
  };


  // Function to extract images from parsed parts
  const extractImageUrls = (parts, activeColor) => {
    const baseUrl = 'https://backend.inkox.com'; // Base URL to prepend
  
    return parts.reduce((acc, part) => {
      const componentKey = Object.keys(part).find((key) => key.startsWith('ProductImageComponent'));
  
      if (componentKey) {
        const componentData = part[componentKey];
  
        // If `colors` exist, handle dynamic color selection
        if (componentData.colors && activeColor) {
          const colorImage = componentData.colors[activeColor];
          if (colorImage) {
            acc[part.name] = `${baseUrl}${colorImage}`;
          } else {
            console.warn(`No image found for color ${activeColor} in part ${part.name}`);
          }
        } else if (componentData.image) {
          // Fallback to a default image if no colors are specified
          acc[part.name] = `${baseUrl}${componentData.image}`;
        }
      }
  
      return acc;
    }, {});
  };
  

  console.log("Thumbnails :", canvasOne);
  console.log("Thumbnails :", canvasTwo);
  console.log("Thumbnails :", canvasThree);
  const generateCanvasThumbnails = () => {
    console.log("generateCanvasThumbnails", canvasOne);
    const thumbnails = {
      front: canvasOne ? canvasOne.toDataURL('image/png') : '',
      back: canvasTwo ? canvasTwo.toDataURL('image/png') : '',
      right: canvasThree ? canvasThree.toDataURL('image/png') : '',
      left: canvasFour ? canvasFour.toDataURL('image/png') : ''
    };

    return thumbnails;
  };

  const handleSizeQuantityChange = (e, size) => {
    const newQuantities = { ...quantities, [size]: parseInt(e.target.value, 10) || 0 };
    setQuantities(newQuantities);
  };


  useEffect(() => {
    // Always generate a new designId each time the tool page is loaded
    const newDesignId = generateUniqueId();

    // Save the new designId in localStorage
    localStorage.setItem('designId', newDesignId);

    // Set the designId in state
    setDesignId(newDesignId);

    console.log('New Design ID:', newDesignId);
  }, []);

  const generateUniqueId = () => '_' + Math.random().toString(36).substr(2, 9);

  const handleObjectSelection = (obj) => {
    if (obj) {
      console.log('Object selected:', obj);
      setActiveSidebar('properties');
      setSelectedObject(obj);


      // Ensure the object type is identified correctly
      let objectType = obj.type;
      if (obj.type === 'image') {
        objectType = 'uploadImage';
        setSelectedImageColors(obj.paletteColors || []);
      } else if (obj.type === 'group') {
        objectType = 'group';
      } else if (obj.type === 'path' || obj.type === 'text') {
        objectType = 'text';
      }

      setSelectedObjectType(objectType);

      // Extract colors and other properties
      const colors = extractColorsFromObject(obj, false); // false means no stroke color in the UI
      setSelectedObjectColors(colors);

      // Handle group objects
      if (obj.type === 'group') {
        obj.getObjects().forEach((childObj) => {
          originalColors.set(childObj, childObj.fill);
        });
      } else {
        originalColors.set(obj, obj.fill);
      }

      // Set the transformation values from the selected object
      setWarpType(obj.warpType || 'None');
      setBend(obj.bend !== undefined ? obj.bend : 0);
      setHorizontalDistort(obj.distortH !== undefined ? obj.distortH : 0);
      setVerticalDistort(obj.distortV !== undefined ? obj.distortV : 0);

      // Update the selected font and category based on the selected object's font
      const selectedFontPath = obj.fontPath || obj.fontFamily || '/fonts/Cheesecake.ttf';
      setSelectedFont(selectedFontPath);

      // Update the category based on the font
      const fontCategory = fonts.find((category) =>
        category.fonts.some((font) => font.fontPath === selectedFontPath)
      );
      if (fontCategory) {
        setSelectedCategory(fontCategory.category);
      } else {
        setSelectedCategory('');
      }

      // Update stroke color and width
      setStrokeColor(obj.stroke || '#000000'); // Default to black if undefined
      setStrokeWidth(obj.strokeWidth !== undefined ? obj.strokeWidth : 0); // Default to 0 if undefined

      setShowApplyTransformationButton(false);
    } else {
      console.log('No object selected');
      setActiveSidebar('main');
      setSelectedObject(null);
      setSelectedObjectType('');
      setSelectedObjectSize({ width: 0, height: 0 });
      setSelectedObjectColors([]);
      setShowColorPalette(false);
    }
  };




  const updateSelectedObjectSize = (size) => {
    console.log('Selected object size:', size);
    setSelectedObjectSize(size);
  };

  const handleColorsExtracted = (colors) => {
    console.log('Extracted colors:', colors);
    setSelectedObjectColors(colors);
  };

  const handleColorChange = (color) => {
    if (selectedObject) {
      const prevState = { ...selectedObject.toObject() };  // Capture the previous state

      selectedObject.set('fill', color);  // Apply the color change
      selectedObject.setCoords();  // Ensure the object updates its coordinates

      [canvasOne, canvasTwo, canvasThree, canvasFour].forEach((canvas) => {
        if (canvas && canvas.contextContainer) {
          canvas.renderAll();  // Render the canvas to reflect the color change
        }
      });

      const newState = selectedObject.toObject();  // Capture the new state
      addToHistory(selectedObject, prevState, newState, 'colorChange');  // Add color change to history
    }
  };


  useEffect(() => {
    fetch('https://backend.inkox.com/api/colors')
      .then((response) => response.json())
      .then((data) => {
        setColorsData(data);  // Save colors data for later comparison
      })
      .catch((error) => console.error('Error fetching colors data:', error));
  }, []);



  useEffect(() => {
    // A map of color names to hex codes and fixed 5-digit IDs

    setIsLoadingData(true); // Start loader

    fetch(`https://backend.inkox.com/api/product/${pid}`)
      .then((response) => response.json())
      .then((data) => {
        setRetailprice(data.sale_price);
        
      console.log("Data :",data);
        // Find the product with the matching id (pid)
        // const matchedProduct = JSON.parse(data);

        console.log('Fetched product data:', data);
  
        console.log('Matched Product:', data);
  
        // Parse the productColor JSON string
        const parsedColors = JSON.parse(data.productColor);
  
        // Extract color names, hex codes, fixed IDs, and sizes
        const colorData = Object.entries(parsedColors).map(([color, sizes]) => {
          const colorDetails = colorHexMap[color] || { hex: "Unknown Hex", id: "00000" };
          return {
            colorName: color,
            hexCode: colorDetails.hex, // Use the fixed hex code
            colorId: colorDetails.id,  // Use the fixed 5-digit ID
            sizes: sizes // The array of sizes for each color
          };
        });

        for (const color in parsedColors) {
          const colorDetails = colorHexMap[color];
          if (colorDetails && colorDetails.id === colorId) {
            setSelectedColor(colorDetails.hex);
            setSelectedColorName(color);
            console.log("colorDetails:",colorDetails.hex); // Set the selected color's hex code
            break;
          }
        }
      
  
        console.log("Selected Colors Data:", colorData);
        // Update the state with color data
        setColors(colorData); // Pass this as the prop to ProductColor component
        setProductData(data);
        setProductPrice(data.price);
        setProductName(data.name);
  
      })
      .catch((error) => console.error('Error fetching product data:', error))
      .finally(() => {
        setIsLoadingData(false); // Stop loader
      });
      
  }, [pid, colorsData]);

  useEffect(() => {
    const foundColor = Object.values(colorHexMap).find(color => color.id === colorId);
    setSpcolor(foundColor.hex);

  },[colorId])


  console.log("ProductColors: ", colors);



// useEffect(() => {
//   setIsLoadingData(true); // Set loading to true before the API call

//   fetch('https://backend.inkox.com/api/tool-apparels')
//     .then((response) => response.json())
//     .then((data) => {
//       console.log('Fetched product data:', data); // Log the fetched data to confirm structure

//       // Find the product with the matching Canvasid
//       const matchedProduct = data.find(product => product.id === parseInt(Canvasid));

//       if (matchedProduct) {
//         console.log('Matched Product:', matchedProduct);

//         // Parse the parts field, which is a stringified JSON
//         const parsedParts = JSON.parse(matchedProduct.parts);
//         console.log('Parsed Parts:', parsedParts);

//         // Extract the images from the parsed parts
//         const extractedImages = extractImageUrls(parsedParts);
//         console.log("Extracted Images:", extractedImages);

//         setPreviewImages(extractedImages);
//         setTshirtDesign(matchedProduct);
//         setParts(parsedParts);
//       } else {
//         console.log('No product found with the given Canvasid:', Canvasid);
//       }
//     })
//     .catch((error) => console.error('Error fetching product data:', error))
//     .finally(() => setIsLoadingData(false)); // Set loading to false after the data is loaded
// }, [Canvasid]);

useEffect(() => {
  const fetchAndUpdateImages = async () => {

    try {
      const response = await fetch(`https://backend.inkox.com/api/tool-apparels/${Canvasid}`);
      const data = await response.json();
      console.log('Fetched product data:', data); // Log the fetched data to confirm structure

      // Find the product with the matching Canvasid
      // const matchedProduct = data.find(product => product.id === parseInt(Canvasid));

      if (data) {
        console.log('Matched Product:', data);

        // Parse the parts field, which is a stringified JSON
        const parsedParts = JSON.parse(data.parts);
        const part = parsedParts[0];
        // const part = JSON.parse(parsedParts.description);
        console.log('Parsed Parts:', part.description);
        console.log('canvas type:', part.canvasType);

        let extractedImages;

        if (data.type === "Masking") {
          // Apply old useEffect functionality for "Masking"
          extractedImages = extractImageUrls(parsedParts); // Old functionality
          console.log("Extracted Images (Masking):", extractedImages);
        } else if (data.type === "Images") {
          // Apply new useEffect functionality for "Images"
          const activeColor = colorname || selectedColorName; // Determine the active color
          console.log('Active Color Name:', activeColor);

          extractedImages = extractImageUrls(parsedParts, activeColor); // Pass active color for "Images"
          console.log("Extracted Images (Images):", extractedImages);
        } else {
          console.warn('Unhandled product type:', data.type);
          extractedImages = {}; // Fallback to an empty object
        }

        // Preserve the current active tab
        setPreviewImages(extractedImages);
        setTshirtDesign(data);

        // Only update parts if they have changed
        setParts((prevParts) => {
          if (JSON.stringify(prevParts) !== JSON.stringify(parsedParts)) {
            return parsedParts;
          }
          return prevParts;
        });
      } else {
        console.log('No product found with the given Canvasid:', Canvasid);
      }
    } catch (error) {
      console.error('Error fetching product data:', error);
    }
  };

  fetchAndUpdateImages();
}, [Canvasid, colorname, selectedColorName]); // Depend on Canvasid and color updates



  
  
  
  
  
  console.log("selectedColorName",selectedColorName);


  console.log("Extracted Images:", previewImages);


  console.log("Parts", parts);




  const handleFillChange = (color) => {
    if (selectedObject) {
      selectedObject.set('fill', color);
      [canvasOne, canvasTwo, canvasThree, canvasFour].forEach((canvas) => {
        if (canvas && canvas.contextContainer) {
          // Ensure the canvas and its context are valid
          canvas.renderAll();
        }
      });
    }
  };

  useEffect(() => {
    if (isPriceSidebarVisible) {
      deselectAllObjects();
    }
  }, [isPriceSidebarVisible]);

  const deselectAllObjects = () => {
    [canvasOne, canvasTwo, canvasThree, canvasFour].forEach((canvas) => {
      if (canvas && canvas.getActiveObject()) {
        canvas.discardActiveObject();
        if (canvas.contextContainer) {
          canvas.clearContext(canvas.contextContainer);  // Safely clear the context
          canvas.renderAll();
        }
      }
    });
  };


  useEffect(() => {
    const handleResize = () => {
      deselectAllObjects();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [canvasOne, canvasTwo, canvasThree, canvasFour]);

  const isTabSwitching = useRef(false);

  // const handleTabClick = (partName) => {
  //   if (isTabSwitching.current) return; // Prevent multiple fast clicks
  
  //   isTabSwitching.current = true; // Set switching flag
  //   setIsSwitchingTab(true);
  //   deselectAllObjects();
    
  //   setTimeout(() => {
  //     saveCanvasDataToLocalStorage(activeTab); // Save current tab data before switching
  //     setActiveTab(partName);
  //     loadCanvasDataFromLocalStorage(partName); // Load the new tab's data
  
  //     isTabSwitching.current = false; // Reset flag after delay
  //     setIsSwitchingTab(false);
  //   }, 600); // Adjust delay as needed (300ms to 500ms works well)
  // };

  const handleTabClick = (partName) => {
 
  
  
    setIsSwitchingTab(true);
    deselectAllObjects();
    
      saveCanvasDataToLocalStorage(activeTab); // Save current tab data before switching
      setActiveTab(partName);
      loadCanvasDataFromLocalStorage(partName); // Load the new tab's data
  
    
      setIsSwitchingTab(false);
   
  };




  useEffect(() => {
    if (canvasOne || canvasTwo || canvasThree || canvasFour) {
      loadCanvasDataFromLocalStorage(activeTab); // Load data for the new active tab
    }
  }, [activeTab, canvasOne, canvasTwo, canvasThree, canvasFour]);

  
  console.log("CanvasOne", canvasOne);
  console.log("CanvasTwo", canvasTwo);
  console.log("CanvasThree", canvasThree);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // Show the confirmation dialog
      event.preventDefault();
      event.returnValue = 'Changes that you made may not be saved';
    };

    const handleUnload = () => {
      // Preserve the values of these keys before clearing
      const id = localStorage.getItem('Id');
      const token = localStorage.getItem('APP_TOKEN');
      const email = localStorage.getItem('Email');
      const name = localStorage.getItem('Name');

      // Clear all localStorage except the specified keys
      localStorage.clear();

      // Restore the preserved values
      if (id) localStorage.setItem('Id', id);
      if (token) localStorage.setItem('APP_TOKEN', token);
      if (email) localStorage.setItem('Email', email);
      if (name) localStorage.setItem('Name', name);

    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('unload', handleUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('unload', handleUnload);
    };
  }, [canvasOne, canvasTwo, canvasThree, canvasFour, parts]);



  const saveCanvasDataToLocalStorage = (partName, uniqueId) => {
    const canvas = getCanvasByPartName(partName);
    if (canvas) {
      const objectsToSave = canvas.getObjects().filter((obj) =>
        obj.designElement || obj.isAddedText || obj.isAddedClipart || obj.uploadImage || obj.colorChanged || obj.isAddedClipart
      );
  
      // Use the unique ID for the entire product across Front, Back, Right, Left etc.
      const productUniqueId = `${uniqueId}_${partName}`;
  
      // Combine partName with productUniqueId for unique storage key
      const storageKey = `${designId}_${partName}`;
  
      let existingCanvasData = [];
      const existingDataJson = localStorage.getItem(storageKey);
      if (existingDataJson) {
        try {
          existingCanvasData = JSON.parse(existingDataJson);
        } catch (error) {
          console.error(`Failed to parse existing data for ${partName}:`, error);
        }
      }
  
      const newCanvasData = objectsToSave.map((obj, index) => {
        if (!obj.id) {
          obj.id = generateUniqueId();
        }
  
        return {
          ...obj.toObject([
            'id',
            'topPercent',
            'imageFile',
            'rotation',
            'leftPercent',
            'widthPercent',
            'heightPercent',
            'pathOffset',
            'scaleX',
            'scaleY',
            'zoomY',
            'zoomX',
            'type',
            'left',
            'top',
            'width',
            'height',
            'designElement',
            'ownMatrixCache',
            'aCoords',
            'path',
            'fromWorkingArea',
            'scaleX',
            'scaleY',
            'zIndex',
            'angle',
            'fill',
            'stroke',
            'element',
            'selectable',
            'cacheTranslationX',
            'cacheTranslationY',
            'designElement',
            'oCoords',
            'selectable',
            'strokeWidth',
            'text',
            'fontSize',
            'fontWeight',
            'fontFamily',
            'textAlign',
            'fontStyle',
            'lineHeight',
            'letterSpacing',
            'underline',
            'overline',
            'linethrough',
            'clipPath',
            'clipartPath',
            'opacity',
            'isAddedText',
            'designElement',
            'isAddedClipart',
            'warpType',
            'bend',
            'distortH',
            'dominantColor',
            "dominant_color",
            'distortV',
            'originalPath',
            'fontPath',
            'fontFamily',
            'originalText',
            'color',
            'mode',
            'alpha',
            'bgremove',
            'singleColor',
            'singleColorMode',
            'makeOneColor',
            'makeColor',
            'makeColorMode',
            'paletteColors',
            'bgRemove',
            'bringToFront',
            'center',
            'isSelected',
            'isCentered',
            'session_id',
            'isFullColor'
          ]),
          zIndex: index,
        };
      });
  
      const combinedCanvasData = existingCanvasData.map((existingObj) => {
        const newObj = newCanvasData.find((newObj) => newObj.id === existingObj.id);
        return newObj || existingObj;
      });
  
      newCanvasData.forEach((newObj) => {
        if (!combinedCanvasData.some((existingObj) => existingObj.id === newObj.id)) {
          combinedCanvasData.push(newObj);
        }
      });
  
      const removedObjectsIds = existingCanvasData
        .filter((existingObj) => !newCanvasData.some((newObj) => newObj.id === existingObj.id))
        .map((obj) => obj.id);
      const updatedCanvasData = combinedCanvasData.filter((obj) => !removedObjectsIds.includes(obj.id));
  
      try {
        localStorage.setItem(storageKey, JSON.stringify(updatedCanvasData));
        console.log(`Saved data for ${partName}:`, updatedCanvasData);
      } catch (error) {
        console.error(`Failed to save data for ${partName}:`, error);
      }
  
      // Save undo/redo history
      const historyData =
        partName === 'Front'
          ? historyOne
          : partName === 'Back'
            ? historyTwo
            : partName === 'Right'
              ? historyThree
              : partName === 'Left'
              ? historyFour
              : null;
  
      if (historyData) {
        try {
          localStorage.setItem(`${designId}-${partName}-history`, JSON.stringify(historyData));
          console.log(`Saved history for ${partName}:`, historyData);
        } catch (error) {
          console.error(`Failed to save history for ${partName}:`, error);
        }
      }
    } else {
      console.warn(`Canvas is not defined for ${partName}`);
    }
  };
  
  const loadCanvasDataFromLocalStorage = (partName, uniqueId) => {
    setIsLoadingData(true);
    setIsLoadingImage(true);  // Set flag to indicate image loading
  
    const canvas = getCanvasByPartName(partName);
    if (!canvas) {
      console.warn('Canvas is not initialized');
      setIsLoadingData(false);
      setIsLoadingImage(false);  // Reset the flag if the canvas is not initialized
      return;
    }
  
    // Disable event listeners to avoid multiple triggers
    canvas.off('object:modified');
    canvas.off('object:added');
    canvas.off('object:removed');
  
    const savedCanvasData = localStorage.getItem(`${designId}_${partName}`);
    console.log("designId",designId);
    console.log("uniqueId",uniqueId);

    
    if (savedCanvasData) {
      try {
        const objectsData = JSON.parse(savedCanvasData);
        canvas.clear(); // Clear the canvas
  
        if (objectsData.length > 0) {
          const sortedObjectsData = objectsData.sort((a, b) => a.zIndex - b.zIndex);
  
          fabric.util.enlivenObjects(sortedObjectsData, (enlivenedObjects) => {
            enlivenedObjects.forEach((obj) => {
              if (obj.type === 'image') {
                setIsLoadingImage(true);
              }
              canvas.add(obj);
              if (obj.designElement) {
                canvas.bringToFront(obj);
              } else {
                canvas.sendToBack(obj);
              }
  
              // Apply transformations if needed
              if (obj.warpType || obj.bend !== 0 || obj.distortH !== 0 || obj.distortV !== 0) {
                applyTransformationsToObject(obj);
              }
  
              if (obj.type === 'image') {
                setIsLoadingImage(false);  // Reset the flag after the image is loaded
              }
            });
            canvas.renderAll();
          });
        }
  
        setIsLoadingData(false);
  
        // Re-enable event listeners
        canvas.on('object:modified', handleObjectModified);
        canvas.on('object:added', handleObjectAdded);
        canvas.on('object:removed', handleObjectRemoved);
      } catch (error) {
        console.error(`Failed to parse canvas data for ${partName}:`, error);
        setIsLoadingData(false);
        setIsLoadingImage(false);  // Reset the flag on error
      }
    } else {
      console.log(`No saved canvas data for ${partName}`);
      setIsLoadingData(false);
      setIsLoadingImage(false);  // Reset the flag if no saved data
    }
  
    // Load history data for the part
    const savedHistoryData = localStorage.getItem(`${designId}-${partName}-history`);
    if (savedHistoryData) {
      try {
        const historyData = JSON.parse(savedHistoryData);
        if (partName === 'Front') {
          setHistoryOne(historyData);
        } else if (partName === 'Back') {
          setHistoryTwo(historyData);
        } else if (partName === 'Right') {
          setHistoryThree(historyData);
        }  else if (partName === 'Left') {
          setHistoryFour(historyData);
        }
      } catch (error) {
        console.error(`Failed to parse history data for ${partName}:`, error);
      }
    }
  };
  





  const clearCanvasAndLocalStorage = () => {
    [canvasOne, canvasTwo, canvasThree, canvasFour].forEach((canvas) => {
      if (canvas) {
        canvas.clear();
      }
    });
    parts.forEach((part) => {
      localStorage.removeItem(part.name);
      localStorage.removeItem(`${part.name}-history`);
    });
  };


  const handleObjectModified = (e) => {

    console.log('Object modified:', e.target);
  };

  const handleObjectAdded = (e) => {
    console.log('Object added:', e.target);
  };

  const handleObjectRemoved = (e) => {
    console.log('Object removed:', e.target);
  };



  // Function to load all parts
  const loadAllPartsData = () => {
    ['Front', 'Back', 'Right', 'Left'].forEach((part) => {
      loadCanvasDataFromLocalStorage(part);
    });
  };

  // Function to handle loading based on isPriceSidebarVisible
  const handleLoading = () => {
    if (isPriceSidebarVisible) {
      loadAllPartsData();  // Load all parts when price sidebar is visible
    }
  };

  // Call handleLoading when needed (on page load, tab switch, etc.)
  useEffect(() => {
    handleLoading();  // Load data when the page loads or when tabs are switched
  }, [isPriceSidebarVisible, activeTab, canvasOne, canvasTwo, canvasThree, canvasFour]);



  const applyTransformationsToObject = (obj) => {
    // Check if the object is not an image and has an originalPath
    if (obj.type === 'image' || !obj.originalPath) {
      console.warn('Transformation is not applicable to this object:', obj);
      return;
    }

    const reformattedSvgPath = reformatSvgPath(obj.originalPath);

    const warpParameters = {
      type: obj.warpType,
      bend: obj.bend / 100,
      distortV: obj.distortV / 100,
      distortH: obj.distortH / 100,
      fill: obj.fill,
    };

    const warpedPath = new Path(reformattedSvgPath);
    warpedPath.warp(warpParameters);

    obj.path = new fabric.Path(warpedPath.output()).path;
    obj.setCoords();
  };



  const handleStartOver = () => {
    clearDesignElementsAndLocalStorage();
    [canvasOne, canvasTwo, canvasThree, canvasFour].forEach((canvas) => {
      if (canvas) {
        const objectsToRemove = canvas.getObjects().filter((obj) => obj.designElement || obj.isAddedText || obj.isAddedClipart);
        objectsToRemove.forEach((obj) => {
          canvas.remove(obj);
        });
        if (canvas.contextContainer) {
          canvas.renderAll();
        }
      }
    });
  };

  const clearDesignElementsAndLocalStorage = () => {
    parts.forEach((part) => {
      const existingDataJson = localStorage.getItem(part.name);
      if (existingDataJson) {
        let existingCanvasData = [];
        try {
          existingCanvasData = JSON.parse(existingDataJson);
        } catch (error) {
          console.error(`Failed to parse existing data for ${part.name}:`, error);
        }

        const filteredCanvasData = existingCanvasData.filter((obj) => !(obj.designElement || obj.isAddedText || obj.isAddedClipart));

        try {
          localStorage.setItem(part.name, JSON.stringify(filteredCanvasData));
          console.log(`Saved filtered data for ${part.name}:`, filteredCanvasData);
        } catch (error) {
          console.error(`Failed to save filtered data for ${part.name}:`, error);
        }
      }

      localStorage.removeItem(`${part.name}-history`);
    });
  };

  const getCanvasByPartName = (partName) => {
    switch (partName) {
      case 'Front':
        return canvasOne;
      case 'Back':
        return canvasTwo;
      case 'Right':
        return canvasThree; 
        case 'Left':
        return canvasFour; 
      default:
        return null;
    }
  };

  const updateCanvasPrice = (price, index) => {
    setCanvasPrices((prevPrices) => {
      const newPrices = [...prevPrices];
      newPrices[index] = price;
      return newPrices;
    });
  };
console.log("CanvasPrices",canvasPrices);
  const handleQuantityChange = (event) => {
    const newQuantity = parseInt(event.target.value, 10);
    setQuantity(newQuantity);
    updateTotalPrice(newQuantity, canvasPrices);
  };
console.log("canvasPrices",canvasPrices);
  const updateTotalPrice = (newQuantity, prices) => {
    if (newQuantity > 0 && prices.some((price) => price > 0)) {
      const totalCanvasPrice = prices.reduce((acc, price) => acc + price, 0);
      setTotalPrice((totalCanvasPrice + basePrice) * newQuantity);
      setPriceDisplay(((totalCanvasPrice + basePrice) * newQuantity).toFixed(2));
    } else {
      setTotalPrice(basePrice);
      setPriceDisplay('Unavailable');
    }
  };

  useEffect(() => {
    updateTotalPrice(quantity, canvasPrices);
  }, [canvasPrices, quantity, basePrice]);

  const calculatePrice = (canvas) => {
    let colorSet = new Set();
    
    if (canvas) {
      canvas.getObjects().forEach((obj) => {
        if (obj.designElement && obj.opacity !== 0) { // Check if opacity is not 0
          const colors = extractColorsFromObject(obj);
          colors.forEach((color) => colorSet.add(color));
        }
      });
    }

    const numberOfColors = colorSet.size;
    console.log("colorSet:",numberOfColors);
    let additionalPrice = 0;

    if (numberOfColors > 0) {
      additionalPrice += 3; // Base price for the first color
      if (numberOfColors > 1) {
        additionalPrice += (numberOfColors - 1) * 1; // Additional price for extra colors
      }
    }

    if (activeTab === 'Front') {
      updateCanvasPrice(additionalPrice, 0);
    } else if (activeTab === 'Back') {
      updateCanvasPrice(additionalPrice, 1);
    } else if (activeTab === 'Right') {
      updateCanvasPrice(additionalPrice, 2);
    }  else if (activeTab === 'Left') {
      updateCanvasPrice(additionalPrice, 3);
    }
  };

  const [thumbnails, setThumbnails] = useState({ front: '', back: '', right: '', left: '' });

  const handleGetPriceClick = () => {
    const generatedThumbnails = generateCanvasThumbnails();
    setThumbnails(generatedThumbnails);
  
    saveCanvasDataToLocalStorage(activeTab);

    setIsPriceSidebarVisible(true);
  };
  
  console.log("Thumbnails :", thumbnails);
  const displayThumbnails = () => {
    return (
      <div className="thumbnails">
        <div className="thumbnail">
          <h3>Front</h3>
          <img src={thumbnails.front} alt="Front" />
        </div>
        <div className="thumbnail">
          <h3>Back</h3>
          <img src={thumbnails.back} alt="Back" />
        </div>
        <div className="thumbnail">
          <h3>Right</h3>
          <img src={thumbnails.right} alt="Right" />
        </div>
          <div className="thumbnail">
          <h3>Left</h3>
          <img src={thumbnails.left} alt="Left" />
        </div>
      </div>
    );
  };

  console.log('front', thumbnails.front);
  console.log('back', thumbnails.back);
  console.log('right', thumbnails.right);
   console.log('left', thumbnails.left);
  const uniqueKey = `inputs_${designId || productName}`;
  const handleClosePriceSidebar = () => {
    localStorage.removeItem(`${uniqueKey}`);
    setIsPriceSidebarVisible(false);
  };

  const reformatSvgPath = (svgPath) => {
    const pathCommands = svgPath.match(/[MmLlHhVvCcSsQqTtAaZz]|-?\d*\.?\d+/g);
    let reformattedPath = '';

    for (let i = 0; i < pathCommands.length; i++) {
      const command = pathCommands[i];
      if (/[MmLlHhVvCcSsQqTtAaZz]/.test(command)) {
        reformattedPath += `\n${command}`;
      } else {
        reformattedPath += ` ${command}`;
      }
    }

    return reformattedPath;
  };
  const applyTransformation = () => {
    const canvas = window._canvas;
    const selectedObject = canvas ? canvas.getActiveObject() : null;

    if (selectedObject && selectedObject.originalPath) {
      const reformattedSvgPath = reformatSvgPath(selectedObject.originalPath);

      const warpParameters = {
        type: warpType,
        bend: bend / 100,
        distortV: verticalDistort / 100,
        distortH: horizontalDistort / 100,
      };
      const warpedPath = new Path(reformattedSvgPath);
      warpedPath.warp(warpParameters);

      // Retain the original font properties and stroke properties
      const newTextPath = new fabric.Path(warpedPath.output(), {
        fill: selectedObject.fill,
        stroke: selectedObject.stroke, // Preserve stroke color
        strokeWidth: selectedObject.strokeWidth, // Preserve stroke width
        designElement: true,
        selectable: true,
        originalPath: selectedObject.originalPath, // Keep the original path in the new object
        originalText: selectedObject.originalText, // Keep the original text for future reference
        warpType: warpParameters.type,
        bend: warpParameters.bend * 100,
        distortV: warpParameters.distortV * 100,
        distortH: warpParameters.distortH * 100,
        left: selectedObject.left,
        top: selectedObject.top,
        scaleX: selectedObject.scaleX,
        scaleY: selectedObject.scaleY,
        fontFamily: selectedObject.fontFamily, // Reapply the font family
        fontPath: selectedObject.fontPath, // Reapply the font path if needed
        paintFirst: "stroke", // Ensure stroke is painted first
        letterSpacing: selectedObject.letterSpacing,
        angle: selectedObject.angle, // Preserve rotation angle

      });

      // Remove the old object and add the new one
      canvas.remove(selectedObject);
      canvas.add(newTextPath);
      canvas.setActiveObject(newTextPath);
      canvas.renderAll();
    } else {
      console.error('Selected object does not have an original path');
    }
  };





  const duplicateObject = (canvas, obj) => {
    obj.clone((cloned) => {
      cloned.set({
        left: obj.left + 10, // Adjust position to avoid overlap
        top: obj.top + 10,
        originalPath: obj.originalPath, // Ensure the originalPath is copied
        warpType: obj.warpType,
        bend: obj.bend,
        letterSpacing: obj.letterSpacing,
        distortH: obj.distortH,
        distortV: obj.distortV,
        id: generateUniqueId(), // Assign a new ID if needed
        fontFamily: obj.fontFamily, // Apply fontFamily to the duplicated object
        fontPath: obj.fontPath, // Apply fontPath to the duplicated object
        originalText: obj.originalText, // Ensure the original text is copied
      });

      // If the object has a fontPath, load and apply it to the cloned object
      if (obj.fontPath) {
        opentype.load(obj.fontPath, (err, font) => {
          if (!err && font) {
            const path = font.getPath(obj.originalText, 0, 0, 72);
            const svgPath = path.toPathData(2);
            cloned.path = new fabric.Path(svgPath).path;
            cloned.fontFamily = obj.fontFamily; // Reapply the original font family
            canvas.add(cloned);
            canvas.setActiveObject(cloned);
            canvas.renderAll();
          } else {
            console.error("Error loading font: ", err);
          }
        });
      } else {
        canvas.add(cloned);
        canvas.setActiveObject(cloned);
        canvas.renderAll();
      }
    });
  };

  const handleProductImageSet = (image) => {
    setProductImage(image);
  };

  const handleClipartRotationChange = (rotation) => {
    setClipartRotation(rotation);
    if (selectedObject) {
      selectedObject.set({
        angle: rotation,
        // originX: "center",
        // originY: "center",
      });
      selectedObject.setCoords();
      const canvas = window._canvas;
      if (canvas) {
        canvas.renderAll();
      }
    }
  };


  function handleOutlineWidth(value) {
    console.log(value);
    const canvas = window._canvas;
    setCOutlineWidth(value);
    const object = canvas.getActiveObject();
    // object.set({ strokeWidth: Number(value), strokeOffset: outlineColor });
    object.set({
      strokeLineJoin: "round",
      textAlign: "center",
      paintFirst: "stroke",

      strokeWidth: value,
    });

    // editor.canvas.add(object);
    canvas.setActiveObject(object);
    object.setCoords();
    canvas.renderAll();
  }


  const handleOutlineColor = (newOutlineColor) => {
    console.log(newOutlineColor);
    setCOutlineColor(newOutlineColor);
    if (selectedObject) {
      selectedObject.set({ stroke: newOutlineColor });
      selectedObject.set({ strokeStyle: newOutlineColor });
      selectedObject.setCoords();
      const canvas = window._canvas;
      canvas.renderAll();
    }
  };
  function setViewportHeight() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
}

window.addEventListener('resize', setViewportHeight);
setViewportHeight();


console.log("spcolor:",spcolor);
const toggleSwitch = () => {
  if (!switchDisabled) {
    setIsOn((prev) => !prev);
    setShowEmbroideryBox(!isOn); // Show toggle box when switched to Embroidery
  }
};
const selectedOption = isOn ? "embroidery" : "screenPrinting";
console.log("selectedOption",selectedOption);

  return (
    <>

<style>
        {`
     .zsiq_floatmain.zsiq_theme1.siq_bR {
    display: none !important;
}
        `}
      </style>


     <HeaderTool handleGetPriceClick={handleGetPriceClick}/>
    <ColorProvider>
      <div style={{ padding: "0px" }} className="all-main" >
        <div style={{ padding: "0px" }} className="container-tool main-cls">
          {!isPriceSidebarVisible && (
            <ConstantSidebar handleSidebarChange={handleSidebarChange}   isOn={isOn} // Pass the switch state
            selectedObjectType={selectedObjectType} />
          )}
          {!isPriceSidebarVisible && (
            <div className="sidebar">
              {activeSidebar === 'main' && (
                <h2 className="tool-text">Let's start a project together!</h2>
              )}
              {!isPriceSidebarVisible && activeSidebar === 'main' && (
                <>
                  <div className="side-main">
                    <div className="option" onClick={() => handleSidebarChange('text')}>
                      <div dangerouslySetInnerHTML={{ __html: svgAddText }} />
                      Add Text
                    </div>
                    <div className="option" onClick={() => handleSidebarChange('clipart')}>
                      <div dangerouslySetInnerHTML={{ __html: svgClipArt }} />
                      Clip Art
                    </div>
                    <div className="option" onClick={() => handleSidebarChange('uploadImage')}>
                      <div dangerouslySetInnerHTML={{ __html: svgUploadImage }} />
                      Upload Image
                    </div>
                    <div className="option" onClick={() => handleSidebarChange('productColor')}>
                      <div dangerouslySetInnerHTML={{ __html: svgProductColor }} />
                      Product Color
                    </div>

                   
                    
                  </div>

                  <div className="printing-type">
  <h3 className="printing-type-text">Printing Type:</h3>

  <div
    className="printing-typess"
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "0px 20px",
      position: "relative",
    }}
  >
    <div
      onClick={toggleSwitch}
      style={{
        width: "42px",
        height: "18px",
        borderRadius: "15px",
        background: isOn ? "#1cbed9" : "#1cbed9",
        position: "relative",
        cursor: switchDisabled ? "not-allowed" : "pointer",
        transition: "background 0.3s",
      }}
    >
      <div
        style={{
          width: "22px",
          height: "22px",
          borderRadius: "50%",
          background: "#fff",
          position: "absolute",
          top: "-2px",
          left: isOn ? "21px" : "0px",
          transition: "left 0.3s",
          boxShadow: "0 2px 5px rgba(0,0,0,0.3)",
        }}
      />
    </div>

    <span
      className="emb-text"
      style={{
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        left: isOn ? "calc(50% - 119px)" : "calc(50% + 45px)",
        fontSize: "14px",
        color: isOn ? "#1cbed9" : "#1cbed9",
        transition: "left 0.3s",
      }}
    >
      {isOn ? "Embriodery" : "Printing"}
    </span>
  </div>

  {switchDisabled && (
    <p className='tagline-emb-screen' style={{ textAlign:'center',marginTop: "10px", color: "#838889", fontWeight: "300", margin:"12px 0px" }}>
      {isOn
        ? "This product is only available for Embroidery."
        : "This product is only available for Printing."}
    </p>
  )}
</div>





 
                  <button onClick={handleStartOver} className="startover">
                    Start Over
                  </button>



                </>
              )}
              {!isPriceSidebarVisible && activeSidebar === 'properties' && selectedObject && (

                <div>
                  {/* <h3>Properties</h3>
                  <p>Type: {selectedObject.type}</p>
                  <p>Width: {selectedObjectSize.width} inches</p>
                  <div></div>
                  <p>Height: {selectedObjectSize.height} inches</p>
                  <ObjectColorComponent
                    canvas={getCanvasByPartName(activeTab)}
                    selectedObject={selectedObject}
                    selectedObjectColors={selectedObjectColors}
                    setSelectedObjectColors={setSelectedObjectColors}
                    selectedColor={selectedColor}
                    setSelectedColor={setSelectedColor}
                    showColorPalette={showColorPalette}
                    setShowColorPalette={setShowColorPalette}
                    inkColors={inkColors}
                    setInkColors={setInkColors}
                    onColorChange={handleColorChange}
                    handleFillChange={handleFillChange}
                    addToHistory={addToHistory}
                    calculatePrice={() => calculatePrice(getCanvasByPartName(activeTab))}
                    saveCanvasDataToLocalStorage={saveCanvasDataToLocalStorage}
                    activeTab={activeTab}
                  /> */}
                  {selectedObject.type === "path" && (
                    <>
                      <AddTextComponent
                        bend={bend}
                        setBend={setBend}
                        horizontalDistort={horizontalDistort}
                        setHorizontalDistort={setHorizontalDistort}
                        verticalDistort={verticalDistort}
                        setVerticalDistort={setVerticalDistort}
                        warpType={warpType}
                        setWarpType={setWarpType}
                        applyTransformation={applyTransformation}
                        setOriginalPath={setOriginalPath}
                        showApplyTransformationButton={showApplyTransformationButton}
                        selectedCategory={selectedCategory} // Pass the selected category here
                        setSelectedCategory={setSelectedCategory} // Pass the function here
                        selectedFont={selectedFont} // Pass the selected font here
                        setSelectedFont={setSelectedFont} // Pass the function here
                        textwidth={selectedObjectSize.width}
                        textheight={selectedObjectSize.height}
                        closePropertiesSidebar={closePropertiesSidebar}


                        isPropertiesView={activeSidebar === 'properties'}
                        canvas={getCanvasByPartName(activeTab)}
                        selectedObject={selectedObject}
                        selectedObjectColors={selectedObjectColors}
                        setSelectedObjectColors={setSelectedObjectColors}
                        selectedColor={selectedColor}
                        setSelectedColor={setSelectedColor}
                        showColorPalette={showColorPalette}
                        setShowColorPalette={setShowColorPalette}
                        inkColors={inkColors}
                        setInkColors={setInkColors}
                        onColorChange={handleColorChange}
                        handleFillChange={handleFillChange}
                        addToHistory={addToHistory}
                        calculatePrice={() => calculatePrice(getCanvasByPartName(activeTab))}
                        getCanvasByPartName={getCanvasByPartName}
                        activeTab={activeTab}
                        activeSidebar={activeSidebar}
                        saveCanvasDataToLocalStorage={saveCanvasDataToLocalStorage}
                      />
                    </>
                  )}

                  {selectedObject.type === "group" && (
                    <>
                      <DetailClipartBox
                        canvas={getCanvasByPartName(activeTab)}
                        selectedObject={selectedObject}
                        isLocked={clipLocked}
                        setIsLocked={setClipLocked}
                        originalColors={originalColors}
                        rotation={clipartRotation}
                        onRotationChange={handleClipartRotationChange}
                        clipwidth={selectedObjectSize.width}
                        clipheight={selectedObjectSize.height}
                        outlineWidth={coutlineWidth}
                        onOutlineWidthChange={handleOutlineWidth}
                        outlineColor={coutlineColor}
                        onOutlineColorChange={handleOutlineColor}
                        selectedObjectColors={selectedObjectColors}
                        setSelectedObjectColors={setSelectedObjectColors}
                        selectedColor={selectedColor}
                        setSelectedColor={setSelectedColor}
                        showColorPalette={showColorPalette}
                        setShowColorPalette={setShowColorPalette}
                        inkColors={inkColors}
                        setInkColors={setInkColors}
                        onColorChange={handleColorChange}
                        handleFillChange={handleFillChange}
                        addToHistory={addToHistory}
                        calculatePrice={() => calculatePrice(getCanvasByPartName(activeTab))}
                        getCanvasByPartName={getCanvasByPartName}
                        closePropertiesSidebar={closePropertiesSidebar}  // <-- Pass the function as a prop here
                        activeTab={activeTab}
                      />
                    </>
                  )}
                  {selectedObject.type === "image" && (
                    <>
                      {/* <ImagePaletteComponent
                  initialPaletteColors={selectedImageColors}
                  canvas={canvas}
                /> */}

                      <DetailImageBox
                        imgwidth={selectedObjectSize.width}
                        imgheight={selectedObjectSize.height}
                        initialPaletteColors={selectedImageColors}
                        canvas={getCanvasByPartName(activeTab)}  // Use the correct canvas reference based on the active tab
                        isPng={isPng}
                        setIsPng={setIsPng}
                        saveCanvasDataToLocalStorage={saveCanvasDataToLocalStorage}
                        activeTab={activeTab}
                        calculatePrice={calculatePrice}
                        closePropertiesSidebar={closePropertiesSidebar}  // <-- Pass the function as a prop here

                      />

                    </>
                  )}
                </div>


              )}
              {!isPriceSidebarVisible && activeSidebar === 'text' && getCanvasByPartName(activeTab) && (
                <AddTextComponent
                  bend={bend}
                  setBend={setBend}
                  horizontalDistort={horizontalDistort}
                  setHorizontalDistort={setHorizontalDistort}
                  verticalDistort={verticalDistort}
                  setVerticalDistort={setVerticalDistort}
                  warpType={warpType}
                  setWarpType={setWarpType}
                  applyTransformation={applyTransformation}
                  setOriginalPath={setOriginalPath}
                  setSelectedCategory={setSelectedCategory} // Pass the function here
                  selectedFont={selectedFont} // Pass the selected font here
                  setSelectedFont={setSelectedFont} // Pass the function here
                  addToHistory={addToHistory}
                  showApplyTransformationButton={showApplyTransformationButton}
                  selectedCategory={selectedCategory} // Pass the selected category here
                  saveCanvasDataToLocalStorage={saveCanvasDataToLocalStorage}
                  activeTab={activeTab}
                  closePropertiesSidebar={closePropertiesSidebar}  // <-- Pass the function as a prop here


                  isPropertiesView={false}
                />
              )}
              {!isPriceSidebarVisible && activeSidebar === 'clipart' && getCanvasByPartName(activeTab) && (
                <AddClipartComponent canvas={getCanvasByPartName(activeTab)} onColorsExtracted={handleColorsExtracted}    closePropertiesSidebar={closePropertiesSidebar}  />
              )}
              {!isPriceSidebarVisible && activeSidebar === 'uploadImage' && getCanvasByPartName(activeTab) && (
                <UploadImageComponent canvas={getCanvasByPartName(activeTab)} setSelectedImageColors={setSelectedImageColors} setIsPng={setIsPng} setImageFile={setImageFile} calculatePrice={calculatePrice}  imageFile={imageFile}  closePropertiesSidebar={closePropertiesSidebar} 
                />
              )}
              {!isPriceSidebarVisible && activeSidebar === 'productColor' && getCanvasByPartName(activeTab) && (
                <ProductColor canvas={getCanvasByPartName(activeTab)} colors={colors}    closePropertiesSidebar={closePropertiesSidebar}  selcolor={setSpcolor} setColorName={setColorName}/>
              )}
               {!isPriceSidebarVisible && activeSidebar === 'aisidebar' && getCanvasByPartName(activeTab) && (
                <AiSidebar setImageFile={setImageFile} setActiveSidebar={setActiveSidebar}  savedImages={savedImages} closePropertiesSidebar={closePropertiesSidebar}
                setSavedImages={setSavedImages}/>
              )}
               {!isPriceSidebarVisible && activeSidebar === 'PrintingType' && getCanvasByPartName(activeTab) && (
                             
                             <div className='printing-type-mobile'>
                           <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "3px",
          backgroundColor: "#f6f6f6",
        }}
      >
        <h5
          style={{
            margin: "0 auto",
            color: "#211e1eaa",
            fontWeight: "400",
            textAlign: "center",
            flexGrow: 1,
          }}
        >
          Choose file 
        </h5>
        
        <button
          onClick={closePropertiesSidebar}
          style={{
            backgroundColor: "transparent",
            border: "none",
            cursor: "pointer",
            fontSize: "18px",
            fontWeight: "bold",
            marginLeft: "auto",
            color: "#aaaa",
            fontWeight: "300",
            float: "right",
          
          }}
          aria-label="Close"
        >
          <img src={close} alt="Close" className="closeicon"/>
        </button>
      </div>

                              
      <h3 className="printing-type-text">Printing Type:</h3>

<div
  className="printing-typess"
  style={{
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0px 20px",
    position: "relative",
    marginBottom:"30px",
  }}
>
  <div
    onClick={toggleSwitch}
    style={{
      width: "42px",
      height: "18px",
      borderRadius: "15px",
      background: isOn ? "#1cbed9" : "#1cbed9",
      position: "relative",
      cursor: switchDisabled ? "not-allowed" : "pointer",
      transition: "background 0.3s",
    }}
  >
    <div
      style={{
        width: "22px",
        height: "22px",
        borderRadius: "50%",
        background: "#fff",
        position: "absolute",
        top: "-2px",
        left: isOn ? "21px" : "0px",
        transition: "left 0.3s",
        boxShadow: "0 2px 5px rgba(0,0,0,0.3)",
      }}
    />
  </div>

  <span
    className="emb-text"
    style={{
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      left: isOn ? "calc(50% - 119px)" : "calc(50% + 45px)",
      fontSize: "14px",
      color: isOn ? "#1cbed9" : "#1cbed9",
      transition: "left 0.3s",
    }}
  >
    {isOn ? "Embriodery" : "Printing"}
  </span>
</div>
                           
                           
                           
                           
                           
                           
                           
                           
                           
                            </div>





                             
                                       )}




            


            </div>
            
          )}


<div
  className={`main ${isPriceSidebarVisible ? 'main-sidebar-visible' : ''}`}
  style={{ width: isPriceSidebarVisible ? '60%' : '60%' }}
>
            <div className="header-tool">
              <div className="contact-info">
                <span>Chat with us</span>
                <span>1-234-456-6789</span>
              </div>
              <div className="account-info">
                <span>My Account</span>
              </div>
            </div>
            {!isPriceSidebarVisible && (
  <>
    {/* <button className="toggle-tabs-btn" onClick={() => setIsTabsVisible(!isTabsVisible)}>
      <img src={tabshirt} alt="Toggle Tabs" />
    </button> */}

    <button className="toggle-tabs-btn" onClick={() => setIsTabsVisible(!isTabsVisible)}>
  <img src={isTabsVisible ? tabopen : tabclose} alt="Toggle Tabs" />
</button>


    {isTabsVisible && (
      <ColorContext.Consumer>
        {({ selectedColor }) => (
          <div className="tabs">
            {parts.map((part) => (
              <button
                key={part.name}
                className={activeTab === part.name ? 'active' : ''}
                onClick={() => handleTabClick(part.name)}
              >
                <img
                  src={previewImages[part.name]}
                  alt={`${part.name} preview`}
                  className="tab-preview-image"
                  style={{ backgroundColor: selectedColor }}
                />
                {part.name}
              </button>
            ))}
          </div>
        )}
      </ColorContext.Consumer>
    )}
  </>
)}
  {isLoadingData ? (
      <Loader />
    ) : (


            <div className={`tab-content ${isPriceSidebarVisible ? 'main-sidebar-visible-tab' : ''}`}>
              
              
              {isPriceSidebarVisible ? (
                
                // Render both CanvasComponentOne and CanvasComponentTwo when isPriceSidebarVisible is true
                <>
                
                  <CanvasComponentOne
                    setCanvasRef={setCanvasOne}
                    updateSelectedObjectSize={updateSelectedObjectSize}
                    onObjectSelected={handleObjectSelection}
                    canvasKey={'Front'}
                    addToHistory={addToHistory}
                    history={historyOne}
                    setHistory={setHistoryOne}
                    saveCanvasDataToLocalStorage={saveCanvasDataToLocalStorage}
                    updateCanvasPrice={(price) => updateCanvasPrice(price, 0)}
                    isPriceSidebarVisible={isPriceSidebarVisible}
                    Canvasid={Canvasid}
                    pid={pid}
                    quantity={quantity}
                    setNumColor={setNumColor}
                    switchBar={selectedOption}
                    setGetValue={setGetValue}
                    designId={designId}
                    influencerid={influencerid}
                    setImagecaptureFront={setImagecaptureFront}
                  />

                  <CanvasComponentTwo
                    setCanvasRef={setCanvasTwo}
                    updateSelectedObjectSize={updateSelectedObjectSize}
                    onObjectSelected={handleObjectSelection}
                    canvasKey={'Back'}
                    addToHistory={addToHistory}
                    history={historyTwo}
                    setHistory={setHistoryTwo}
                    saveCanvasDataToLocalStorage={saveCanvasDataToLocalStorage}
                    updateCanvasPrice={(price) => updateCanvasPrice(price, 1)}
                    Canvasid={Canvasid}
                    pid={pid}
                    quantity={quantity}
                    setNumColor={setNumColor}
                    switchBar={selectedOption}
                    isPriceSidebarVisible={isPriceSidebarVisible}
                    designId={designId}
                    influencerid={influencerid}
                    setImagecaptureBack={setImagecaptureBack}

                  />
                    <CanvasComponentThree
                             setCanvasRef={setCanvasThree}
                            updateSelectedObjectSize={updateSelectedObjectSize}
                            onObjectSelected={handleObjectSelection}
                            canvasKey={'Right'}
                            addToHistory={addToHistory}
                            history={historyThree}
                            setHistory={setHistoryThree}
                            saveCanvasDataToLocalStorage={saveCanvasDataToLocalStorage}
                            updateCanvasPrice={(price) => updateCanvasPrice(price, 2)}
                            Canvasid={Canvasid}
                            pid={pid}
                            quantity={quantity}
                            setNumColor={setNumColor}
                            switchBar={selectedOption}
                            isPriceSidebarVisible={isPriceSidebarVisible}
                            designId={designId}
                            influencerid={influencerid}
                            setImagecaptureRight={setImagecaptureRight}


                          />
                            <CanvasComponentFour
                             setCanvasRef={setCanvasFour}
                            updateSelectedObjectSize={updateSelectedObjectSize}
                            onObjectSelected={handleObjectSelection}
                            canvasKey={'Left'}
                            addToHistory={addToHistory}
                            history={historyFour}
                            setHistory={setHistoryFour}
                            saveCanvasDataToLocalStorage={saveCanvasDataToLocalStorage}
                            updateCanvasPrice={(price) => updateCanvasPrice(price, 3)}
                            Canvasid={Canvasid}
                            pid={pid}
                            quantity={quantity}
                            setNumColor={setNumColor}
                            switchBar={selectedOption}
                            isPriceSidebarVisible={isPriceSidebarVisible}
                            designId={designId}
                            influencerid={influencerid}
                            setImagecaptureLeft={setImagecaptureLeft}

                          />
                </>
              ) : (
                // Default rendering logic when the price sidebar is not visible
                parts.map((part, index) => (
                  <div
                    key={part.name}
                    style={{ display: activeTab === part.name ? 'block' : 'none' }}
                    className="abd"
                  >
                    {activeTab === part.name && (
                      <>
                        {part.name === 'Front' && (
                          <CanvasComponentOne
                            setCanvasRef={setCanvasOne}
                            updateSelectedObjectSize={updateSelectedObjectSize}
                            onObjectSelected={handleObjectSelection}
                            canvasKey={part.name}
                            addToHistory={addToHistory}
                            history={historyOne}
                            setHistory={setHistoryOne}
                            saveCanvasDataToLocalStorage={saveCanvasDataToLocalStorage}
                            updateCanvasPrice={(price) => updateCanvasPrice(price, index)}
                            isPriceSidebarVisible={isPriceSidebarVisible}
                            Canvasid={Canvasid}
                            pid={pid}
                            quantity={quantity}
                            setNumColor={setNumColor}
                            switchBar={selectedOption}
                            designId={designId}
                            influencerid={influencerid}
                            setImagecaptureFront={setImagecaptureFront}

                          />
                        )}
                        {part.name === 'Back' && (
                          <CanvasComponentTwo
                            setCanvasRef={setCanvasTwo}
                            updateSelectedObjectSize={updateSelectedObjectSize}
                            onObjectSelected={handleObjectSelection}
                            canvasKey={part.name}
                            addToHistory={addToHistory}
                            history={historyTwo}
                            setHistory={setHistoryTwo}
                            saveCanvasDataToLocalStorage={saveCanvasDataToLocalStorage}
                            updateCanvasPrice={(price) => updateCanvasPrice(price, index)}
                            Canvasid={Canvasid}
                            pid={pid}
                            quantity={quantity}
                            setNumColor={setNumColor}
                            isPriceSidebarVisible={isPriceSidebarVisible}
                            switchBar={selectedOption}
                            designId={designId}
                            influencerid={influencerid}
                            setImagecaptureBack={setImagecaptureBack}

                          />
                        )}
                        {part.name === 'Right' && (
                          <CanvasComponentThree
                             setCanvasRef={setCanvasThree}
                            updateSelectedObjectSize={updateSelectedObjectSize}
                            onObjectSelected={handleObjectSelection}
                            canvasKey={part.name}
                            addToHistory={addToHistory}
                            history={historyThree}
                            setHistory={setHistoryThree}
                            saveCanvasDataToLocalStorage={saveCanvasDataToLocalStorage}
                            updateCanvasPrice={(price) => updateCanvasPrice(price, index)}
                            Canvasid={Canvasid}
                            pid={pid}
                            quantity={quantity}
                            setNumColor={setNumColor}
                            isPriceSidebarVisible={isPriceSidebarVisible}
                            designId={designId}
                            influencerid={influencerid}
                            setImagecaptureRight={setImagecaptureRight}

                            switchBar={selectedOption}
                          />
                        )}
                        {part.name === 'Left' && (
                          <CanvasComponentFour
                             setCanvasRef={setCanvasFour}
                            updateSelectedObjectSize={updateSelectedObjectSize}
                            onObjectSelected={handleObjectSelection}
                            canvasKey={part.name}
                            addToHistory={addToHistory}
                            history={historyFour}
                            setHistory={setHistoryFour}
                            saveCanvasDataToLocalStorage={saveCanvasDataToLocalStorage}
                            updateCanvasPrice={(price) => updateCanvasPrice(price, index)}
                            Canvasid={Canvasid}
                            pid={pid}
                            quantity={quantity}
                            setNumColor={setNumColor}
                            isPriceSidebarVisible={isPriceSidebarVisible}
                            designId={designId}
                            influencerid={influencerid}
                            setImagecaptureLeft={setImagecaptureLeft}

                            switchBar={selectedOption}
                          />
                        )}
                      </>
                    )}
                  </div>
                ))
              )}

              
            </div>
                )}

 

          </div>
          {isPriceSidebarVisible && (
            <PriceSidebar
              show={isPriceSidebarVisible}
              handleClose={handleClosePriceSidebar}
              quantity={quantity}
              handleQuantityChange={handleQuantityChange}
              priceDisplay={priceDisplay}
              setPriceDisplay={setPriceDisplay} // <-- Pass setPriceDisplay here
              canvasPrices={canvasPrices}
              thumbnails={thumbnails}
              name={productName}
              selectedColor={selectedColor}
              quantities={quantities}
              handleSizeQuantityChange={handleSizeQuantityChange}
              productData={productData}
              deselectAllObjects={deselectAllObjects}
              zipCode={zipCode}
              uniqueKey={uniqueKey}
              designName={designName}
              email={email}
              setDesignName={setDesignName}
              setEmail={setEmail}
              setZipCode={setZipCode}
              productName={productName}
              totaledQuantity={quantity}
            canvasOne={canvasOne}   // Pass canvasOne
            canvasTwo={canvasTwo}   // Pass canvasTwo
            canvasThree={canvasThree}   // Pass canvasThree
            canvasFour={canvasFour}
            extractColorsFromObject={extractColorsFromObject}
            selectedFont={selectedFont}
            Canvasid={Canvasid}
            totalPrice={priceDisplay}
            basePrice={basePrice}
            designId={designId}
            colors={colors}
            selcolor={spcolor}
            retailprice={retailprice}
            numcolor={numcolor}
            sale_price={retailprice}
            switchBar={selectedOption}
            imagecaptureFront={imagecaptureFront}
            imagecaptureBack={imagecaptureBack}
            imagecaptureRight={imagecaptureRight}
            imagecaptureLeft={imagecaptureLeft}
            pid={pid}

            />
          )}

{showEmbroideryBox && !isPriceSidebarVisible && (
  <div className="toggle-box">
    {!showEmbroideryText && (
      <div
        className="toggle-icon"
        style={{ cursor: 'pointer' }}
        onClick={() => setShowEmbroideryText(true)} // Show text and hide icon
      >
        <img src={iicon} alt="Embroidery Icon" />
      </div>
    )}
    <div
      className={`toggle-text-container ${showEmbroideryText ? 'open' : 'closed'}`} // Apply sliding animation
    >
      <div className="toggle-text">
        <span>Embroidery is done by a machine that stitches threads directly into the product. 
        </span>
        <button
          className="close-toggle"
          onClick={() => setShowEmbroideryText(false)} // Show icon and hide text
          style={{
            marginLeft: '10px',
            backgroundColor: 'transparent',
            border: 'none',
            cursor: 'pointer',
            fontSize: '18px',
          }}
        >
          ✖
        </button>
      </div>
    </div>
  </div>
)}


        </div>
        {/* <div className="export-section">
          <ExportButton
            productName={productName}
            totalQuantity={quantity}
            canvasOne={canvasOne}   // Pass canvasOne
            canvasTwo={canvasTwo}   // Pass canvasTwo
            canvasThree={canvasThree}   // Pass canvasThree
            extractColorsFromObject={extractColorsFromObject}
            selectedFont={selectedFont}
            Canvasid={Canvasid}
            totalPrice={priceDisplay}
            basePrice={basePrice}
            zipCode={zipCode}
              designName={designName}
              email={email}
              selcolor={spcolor}
              
          />
        </div> */}
         {!isPriceSidebarVisible && (
<div className='containers'>
        <div className="footer-tool">
          <div className="product-ui">
            {/* <div className="add-products">
              <button className="add-products-button">+ Add Products</button>
            </div> */}
            <div className="product-details">
              <div className="product-image">
                <ColorContext.Consumer>
                  {({ selectedColor }) => (
                    <img src={previewImages[activeTab]} alt="Product" style={{ backgroundColor: selectedColor }} />
                  )}
                </ColorContext.Consumer>
              </div>

              <div className="product-info">
                <div>
                  <span className="label">Product:</span>
                  <span>{productName}</span>
                  <a href="#" className="change-link" onClick={openModal}>
          Change Product
        </a>

        <ChangeProductModal isOpen={isModalOpen} onClose={closeModal} />
                </div>
                <div>
                  <span className="label">Color:</span>
                  <ColorContext.Consumer>
                    {({ selectedColor, selectedColorName }) => (
                      <span
                        style={{
                          display: 'inline-flex',
                          alignItems: 'center',
                          marginTop: '2px',
                          verticalAlign: 'middle'
                        }}
                      >
                        <span
                          style={{
                            backgroundColor: selectedColor,
                            width: '22px',
                            height: '22px',
                            marginRight: '10px',
                            marginLeft: '5px',
                            border: '1px solid #b5afaf',
                            borderRadius: '4px'
                          }}
                        ></span>
                        <span>
                          <span> {selectedColorName}</span>
                          <span className="color-code">Color Code: {selectedColor}</span>
                        </span>
                      </span>
                    )}
                  </ColorContext.Consumer>
                  <span className="change-colors" onClick={() => handleSidebarChange('productColor')}>
                    Change Color
                  </span>
                </div>
              </div>
            </div>
            <div className="actions">
              {/* <button className="save-share-button">
                <span>
                  <img src={save} />
                </span>
                <span>Save | Share Taha</span>
              </button> */}
              <button className="get-price-button" onClick={handleGetPriceClick}>
                <span>
                  <img src={envelope} alt='envelope' />
                </span>
                <span>Get Price </span>
              </button>
            </div>
          </div>
        </div>
        </div>
         )}
{/* 
        <div className="">
          <div className="history-footer">
            <HistoryDisplay
              history={
                activeTab === 'Front'
                  ? historyOne
                  : activeTab === 'Back'
                    ? historyTwo
                    : historyThree
              }
            />
          </div>
          <h3>Total Price: ${priceDisplay}</h3>

          <label>
            Quantity:
            <input type="number" value={quantity} onChange={handleQuantityChange} min="0" />
          </label>
          <button>Save | Share</button>
          <button onClick={() => {
            handleGetPriceClick();
            deselectAllObjects();
          }}>
            Get Price
          </button>

        </div> */}
      </div>
    </ColorProvider>
    <div className='footer-tool-page'>
    <Footer/>
    </div>
    </>
  );
};

export default View;