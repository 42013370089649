import React from "react";
import SignUpForm from "../components/forms/signUpForm/SignUpForm";
import LayoutWrapper from "../layout/layoutWrapper/LayoutWrapper";


const Signup = () => {
  return (
    <LayoutWrapper>
    <div className="signUp">
      <SignUpForm />
    </div>
        </LayoutWrapper>

  );
};

export default Signup;
