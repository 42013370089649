import React, { useEffect, useState } from "react";
import productFilterImage from "../assets/images/shirt3.png";
import LayoutWrapper from "../layout/layoutWrapper/LayoutWrapper";
import Faqs from "../widgets/faqs/Faqs";
import ProductDetail from "../widgets/productDetail/ProductDetail";
import RelatedProduct from "../widgets/relatedProduct/RelatedProduct";
import Reviews from "../widgets/reviews/Reviews";
import TipOfTheDay from "../widgets/tipOfTheDay/TipOfTheDay";
import { useLocation } from "react-router-dom";

const SingleProductDetail = () => {
  const tipOfTheDay = {
    title:
      "<span class='text-red'>Tip</span> of the <span class='text-skyblue'>Day</span>",
    description:
      "Page yield is how many pages your printer cartridge can produce before it needs replacement. To calculate the Paper cost, here's another math task:",
  };

  const [rproduct, setRProduct] = useState(0);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [selectedColors, setSelectedColors] = useState({});
  const location = useLocation();
  const pathSegments = location.pathname.split("/");

  const selectedProductId = rproduct.id;
  console.log("rproduct",rproduct);

  useEffect(() => {
    const fetchData = () => {
      fetch(
        `https://backend.inkox.com/api/product-subcategories-all/${rproduct}`
      )
        .then((response) => response.json())
        .then((data) => {
          const productsArray = data.map((item) => item.products).flat();
          const relatedProducts = productsArray.filter(
            (product) => product.id !== rproduct.id
          );
          setFilteredProducts(relatedProducts);

          // Initialize selectedColors for default image preview
          const initialColors = {};
          productsArray.forEach((product) => {
            const subcat = product?.nestedProperty?.subcat || product.subcat;

            if (subcat) {
              const productImages =
                typeof subcat === "string" ? JSON.parse(subcat) : subcat;
              const firstColor = Object.keys(productImages)[0];
              initialColors[product.id] = firstColor || null;
            }
          });
          setSelectedColors(initialColors);
        })
        .catch((error) => console.error("Error fetching related products:", error));
    };

    const fetchTimeout = setTimeout(fetchData, 500); // Adjust delay as needed

    return () => clearTimeout(fetchTimeout);
  }, [selectedProductId, pathSegments]);

  const handleColorSelect = (productId, colorKey) => {
    setSelectedColors((prev) => ({
      ...prev,
      [productId]: colorKey,
    }));
  };

  const productsWithFilters = filteredProducts.slice(0, 8).map((product) => {
    const subcat = product?.nestedProperty?.subcat || product.subcat;
    let currentImage = productFilterImage; // Default image

    try {
      const productImages = subcat
        ? typeof subcat === "string"
          ? JSON.parse(subcat)
          : subcat
        : null;

      // Normalize the selected color key
      const normalizeKey = (key) =>
        key.toLowerCase().replace(/[^a-z0-9]/g, "");

      const selectedColor = selectedColors[product.id]
        ? normalizeKey(selectedColors[product.id])
        : null;

      if (selectedColor && productImages) {
        const normalizedImages = Object.fromEntries(
          Object.entries(productImages).map(([key, value]) => [
            normalizeKey(key),
            value,
          ])
        );

        if (
          product.vendor === "snscanada" &&
          normalizedImages[selectedColor]?.front
        ) {
          currentImage = `https://backend.inkox.com/${normalizedImages[selectedColor].front}`;
        } else if (normalizedImages[selectedColor]) {
          currentImage = `https://backend.inkox.com/${normalizedImages[selectedColor]}`;
        }
      }
    } catch (error) {
      console.error("Error parsing subcat:", error);
    }

    return {
      image: `${currentImage}`,
      topSeller: false,
      title: product.name,
      size: product.productSize
        ? JSON.parse(product.productSize).join(", ")
        : "",
      rating: "4",
      reviews: "548",
      link: `/products/${pathSegments[2]}/${decodeURIComponent(
        pathSegments[3]
      )}/subCategory/${decodeURIComponent(pathSegments[5])}/${product.name.replace(/\s+/g, "-")}/${
        product.id
      }`,
    };
  });

  const reviews = [
    {
      user: "Jimmy D.",
      rating: 4,
      verified: true,
      time: "33 hours ago",
      review:
        "Would I recommend this company? Early signs is a resounding YES! <br /> I looked at this company in depth, after requesting a phone call to discuss my r..",
      replyes: [
        {
          user: "Printofly",
          department: "Sales Department",
          time: "33 hours ago",
          reply:
            "Would I recommend this company? Early signs is a resounding YES! <br /> I looked at this company in depth, after requesting a phone call to discuss my r..",
        },
      ],
    },
  ];

  return (
    <LayoutWrapper search={true}>
      <ProductDetail setRProduct={setRProduct} />
      <Reviews data={reviews} />
      <RelatedProduct
        data={productsWithFilters}
        handleColorClick={handleColorSelect}
      />
      <Faqs />
      <TipOfTheDay bgColor={"#fff"} data={tipOfTheDay} />
    </LayoutWrapper>
  );
};

export default SingleProductDetail;
