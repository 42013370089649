import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import brandImage3 from "../assets/images/better-pricing.webp";
import brandImage1 from "../assets/images/fast-delivery.webp";
import newArrivalImage1 from "../assets/images/new-arrival1.webp";
import newArrivalImage2 from "../assets/images/new-arrival2.webp";
import newArrivalImage3 from "../assets/images/new-arrival3.webp";
import newArrivalImage4 from "../assets/images/new-arrival4.webp";
import offerBannerImage from "../assets/images/offer-bnr.png";
import brandImage4 from "../assets/images/review-repair.webp";
import brandImage2 from "../assets/images/satisfactions.webp";
import testimonialIamge from "../assets/images/testimonial.png";
import logowhite from "../assets/images/logowhite.png";
import testimonialIamgeone from "../assets/images/testione.png";
import testimonialIamgetwo from "../assets/images/testitwo.png";

import LayoutWrapper from "../layout/layoutWrapper/LayoutWrapper";
import Banner from "../widgets/banner/Banner";
import BrandDeserves from "../widgets/brandDeserves/BrandDeserves";
import NewArrival from "../widgets/newArrival/NewArrival";
import NewArrivalOrignal from "../widgets/newArrival/NewArrivalOrignal";
import shipping from "../widgets/newArrival/NewArrival";
import OfferBanner from "../widgets/offerBanner/OfferBanner";
import Testimonials from "../widgets/testimonials/Testimonials";
import TipOfTheDay from "../widgets/tipOfTheDay/TipOfTheDay";
import TrendingProducts from "../widgets/trendingProducts/TrendingProducts";
import BrandGrid from "../widgets/brands/brandgrid";
import "./home.css";


const Home = () => {
  const [product, setProduct] = useState([]);
  const [images, setImages] = useState([]);
  const [trendingProducts, setTrendingProducts] = useState([]);
  const [newArrivalCategories, setNewArrivalCategories] = useState([]);
  const [popupVisible, setPopupVisible] = useState(true); // State to control popup visibility

  const navigate = useNavigate();
  const closePopup = () => {
    setPopupVisible(false); // Close the popup when the close button is clicked
  };
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const productResponse = await fetch(
  //         `https://backend.inkox.com/api/product/`
  //       );
  //       const productData = await productResponse.json();
  //       setProduct(productData);

  //       // Filter products where trending_prod is "1"
  //       const trending = productData.filter(
  //         (item) => item.trending_prod === "1"
  //       );

  //       // Map the filtered products to match the structure of trendingProducts
  //       const trendingMapped = trending.map((item) => ({
  //         image: `https://backend.inkox.com${item.productImages.split(",")[0].trim()}`,
  //         category: item.category?.name || "T-Shirt",
  //         title: item.name,
  //         startingPrice: item.price, // You can replace this with item.byuing_price if available
  //       }));

  //       // Set the trending products in state
  //       setTrendingProducts(trendingMapped);

  //       // Split the productImages string into an array of image URLs
  //       const imageArray = productData.productImages
  //         .split(",")
  //         .map((img) => `https://backend.inkox.com${img.trim()}`);
  //       setImages(imageArray);
  //     } catch (error) {
  //       console.error("Error fetching product:", error);
  //     }
  //   };
  //   fetchData();
  // }, []);

  // useEffect(() => {
  //   const fetchCategories = async () => {
  //     try {
  //       const categoryResponse = await fetch(
  //         `https://backend.inkox.com/api/categories/`
  //       );
  //       const categoryData = await categoryResponse.json();

  //       // Filter categories where trending_category is "1"
  //       const trendingCategories = categoryData.filter(
  //         (category) => category.trending_category === "1"
  //       );

  //       // Map the filtered categories to match the New Arrival structure
  //       const mappedCategories = trendingCategories.map((category) => ({
  //         image: `https://backend.inkox.com/${category.icon_path}`,
  //         subTitle: "NEW ARRIVALS",
  //         title: category.name,
  //         link: `/category/${category.id}`, // Assuming you have a route to the category page
  //       }));

  //       // Set the filtered categories in state
  //       setNewArrivalCategories(mappedCategories);
  //     } catch (error) {
  //       console.error("Error fetching categories:", error);
  //     }
  //   };

  //   fetchCategories();
  // }, []);

  const banner = {

    title: `EXCLUSIVE <br /> DESIGNS`,
    link: "/",
  };

  const newArrival = {
    data: [
      {
        image: newArrivalImage1,
        subTitle: "NEW ARRIVALSabd",
        title: `SPRING SHIRTSsasa`,
        link: "/",
      },
      {
        image: newArrivalImage2,
        subTitle: "NEW ARRIVALSsaa",
        title: `SPRING SHIRTSsas`,
        link: "/",
      },
      {
        image: newArrivalImage3,
        subTitle: "NEW ARRIVALSxsxs",
        title: `SPRING SHIRTSsdsd`,
        link: "/",
      },
      {
        image: newArrivalImage4,
        subTitle: "NEW ARRIVALSsss",
        title: `SPRING SHIRTSsss`,
        link: "/",
      },
      
    ],
  };

  const tipOfTheDay = {
    // image: tipOfTheDayImage,
    title:
      "<span class='text-red'>Tip</span> of the <span class='text-skyblue'>Day</span>",
    description:
      "Page yield is how many pages your printer cartridge can produce before it needs replacement. To calculate the Paper cost, here's another math task:",
  };

  const offerBanner = {
    image: offerBannerImage,
    preTitle: "HAPPY DAY",
    title: `40% off`,
    postTitle: "all premium t-shirt",
    link: "/",
  };

  const brandDeserves = [
    {
      image: brandImage1,
      title: "FAST RUSH DELIVERY",
      description:
        "We specialize in rush deliveries, ensuring timely service without compromising on quality. Every order is accepted only if we’re 100% confident in meeting your deadline.",
    },
    {
      image: brandImage2,
      title: "BETTER PRICING",
      description:
        "Get the best value for premium service. Our pricing is tailored to ensure you receive top-notch quality at a competitive rate, without hidden costs.",
    },
    {
      image: brandImage3,
      title: "INDUSTRY LEADING SATISFACTION GUARANTEE",
      description:
        "your satisfaction is our priority. We are committed to delivering exceptional results, backed by a satisfaction guarantee to ensure your peace of mind.",
    },
    {
      image: brandImage4,
      title: "DESIGN REVIEW & REPAIR",
      description:
        "Our experts meticulously review and repair designs as needed to ensure the highest quality. You can trust that every detail will be perfected.",
    },
  ];

  const testimonials = [
    {
      image: testimonialIamge,
      name: "David Smith",
      rating: 4,
      designation: "CEO, Hoaak Inc.",
      description:
        "Rchitect scalable e-tailers after cross media theme areas. Dynamically disintermediate ricks-and-clicks methods of empowerment through myocardinate backend",
    },
    {
      image: testimonialIamgeone,
      name: "Michael Chung",
      rating: 4,
      designation: "Manager, Yankees Baseball Inc.",
      description:
        "The team at Inkox is the best.  I have now run close to 1000 shirts and over 400 hats and they continue to be the best quality in the market.  They are extremely collaborative and professional.  I will not go anywhere else.",
    },
    {
      image: testimonialIamgetwo,
      name: "Rachel HL",
      rating: 4,
      designation: "CEO, AG windows & Doors Inc.",
      description:
        "Great service! Got my package today and I'm 100% satisfied with the quality and looks of my hats! Definitely recommend this company! Thank you guys!",
    },

  ];

  return (

    <div className="home-page">
           {/* {popupVisible && (
        <div className="popup-overlay">
          <div className="popup-container">
            <img src={logowhite} alt="Logo" className="popup-logo" />
            <hr></hr>
            <div className="home-popup">
            <h1>Welcome to INKOX 🎉</h1>
            <h2>We’re so excited to have you here!</h2>
            <p className="popup-text">Our website is in the final phase of development and will be live very soon.

Stay tuned – great things are coming your way!</p>
<p className="popup-text">Meanwhile, explore the world’s most advanced T-shirt designer tool! </p>
</div>
            <button className="shop-button" onClick={closePopup}>
              Explore
            </button>
          </div>
        </div>
      )} */}
    <LayoutWrapper>
      <Banner data={banner} />
      {/* abdullah
      <button onClick={() => navigate("/invoice")}>Go to Invoice</button> */}

      <NewArrival data={newArrival?.data} />
      <TrendingProducts  />
     
      <OfferBanner data={offerBanner} />

    
      <NewArrivalOrignal data={newArrival?.data} />
      <BrandGrid />
      <BrandDeserves data={brandDeserves} />
      <Testimonials  data={testimonials} />
     
      <TipOfTheDay data={tipOfTheDay} />
    </LayoutWrapper>
    </div>

  );
};

export default Home;
