import React, { useEffect, useState } from "react";
import productCategoryImage from "../assets/images/shirt2.png";
import LayoutWrapper from "../layout/layoutWrapper/LayoutWrapper";
import Faqs from "../widgets/faqs/Faqs";
import ProductListing from "../widgets/productListing/ProductListing";
import TipOfTheDay from "../widgets/tipOfTheDay/TipOfTheDay";
import { getProducts } from "../redux_apis/redux_products/slice";
import { useAppSelector } from "../redux/hooks";
import { useParams } from "react-router-dom";

const Loader = () => <div className="spinner"></div>;

const ProductWithCategory = () => {
  const { data } = useAppSelector(getProducts);
  const [productsCategories, setProductsCategories] = useState([]);
  const { id } = useParams(); // ID from route params
  const [subCategories, setSubCategories] = useState([]);
  const [categoryName, setCategoryName] = useState(""); // Add state for category name
  const [loadingSubCategories, setLoadingSubCategories] = useState(true); // Add loading state

  const tipOfTheDay = {
    title:
      "<span class='text-red'>Tip</span> of the <span class='text-skyblue'>Day</span>",
    description:
      "Page yield is how many pages your printer cartridge can produce before it needs replacement. To calculate the Paper cost, here's another math task:",
  };

  // Fetch subcategories and category name
  useEffect(() => {
    setLoadingSubCategories(true); // Start loading

    // Fetch subcategories
    fetch("https://backend.inkox.com/api/product-sub-categories")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        console.log("Subcategories:", data);
        const filteredSubCategories = data.filter(
          (subCategory) => subCategory.category_id === parseInt(id)
        );
        setSubCategories(filteredSubCategories);
        setLoadingSubCategories(false); // Stop loading when data is fetched
      })
      .catch((error) => {
        console.error("Error fetching subcategory data:", error.message);
        setLoadingSubCategories(false); // Stop loading on error
      });

    // Fetch category name
    fetch("https://backend.inkox.com/api/product-categories")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const category = data.find(
          (category) => category.id === parseInt(id)
        );
        setCategoryName(category?.name || "Category"); // Set category name
      })
      .catch((error) => {
        console.error("Error fetching category name:", error.message);
      });
  }, [id]);

  // Generate the payload dynamically from subcategories
  const productsWithCategories = subCategories.map((subCategory) => ({
    image: `https://backend.inkox.com//storage/${subCategory.icon_path}`, // or subCategory.icon_path if you want dynamic images
    title: subCategory.name, // Using subCategory name for title
    link: `${subCategory.name.replace(/\s+/g, "-")}/${subCategory.id}`, // Replace spaces with '-'
  }));

  return (
    <LayoutWrapper>
      {loadingSubCategories ? (
        <Loader /> // Display loader
      ) : (
        <>
          <ProductListing
            data={productsWithCategories}
            selectedCategoryName={categoryName} // Pass the category name
          />
          <Faqs />
          <TipOfTheDay bgColor={"#fff"} data={tipOfTheDay} />
        </>
      )}
    </LayoutWrapper>
  );
};

export default ProductWithCategory;
