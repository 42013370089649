import { MenuFoldOutlined } from "@ant-design/icons";
import { Col, Drawer, Menu, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo.webp";
import Cart from "../../assets/images/cart.png";
import login from "../../assets/images/login.png";
import envelope from '../../view/assets/Vector (3).png';


import bumberger from "../../assets/humbergertool.webp";

import Container from "../../components/container/Container";
import { selectUser } from "../../redux_apis/redux_login/slice";
import { removeFromLocalStorage } from "../../utils/helperFunctions";
import ChatTalk from "../../widgets/chatTalk/ChatTalk";
import './headerTool.css';
import PriceSidebar from '../../view/components/PriceSidebar';



const HeaderTool = ({ handleGetPriceClick }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [current, setCurrent] = useState("/");
  const [categories, setCategories] = useState([]);

  const user = useSelector(selectUser);
  const categoryItems = categories.map(category => ({
    label: (
      <a href={`/products/${category.name.replace(/\s+/g, "-")}/${category.id}`}>
        {category.name}
      </a>
    ),
    key: `category-${category.id}`,
    children: category.subcategories.map(subcategory => ({
      label: (
        <a href={`/products/${category.name.replace(/\s+/g, "-")}/${category.id}/${subcategory.name.replace(/\s+/g, "-")}/${subcategory.id}`}>
          {subcategory.name}
        </a>
      ),
      key: `${subcategory.id}`,
    })),
  }));
  const items = [
    {
      label: "products",
      key: "products",
    },

    {
      label: "Custom Apparel",
      key: "custom-apparel",
      children: categoryItems,  // Dynamically generated categories
    },
    
    {
      label: "Help Center",
      key: "help-center",
    },
    {
      label: user?.name ? user?.name : "Login",
      key: user?.name ? user?.name : "login",
      children: [
        {
          label: "Update Profile",
          key: "update-profile",
        },
        {
          label: "Update Password",
          key: "update-password",
        },
        {
          label: "Logout",
          key: "logout",
        },
      ],
    },

    {
      label: "DESIGN NOW",
      key: "designer?id=150&pid=94&colorId=700005",
      className: "design-now-btn", // Added className for custom styling
      url:'http://localhost:3000/designer?id=150&pid=73&colorId=700007',

    }
  ];
  // sdsdsd
  useEffect(() => {
    if (location?.pathname) {
      let route = location?.pathname?.split("/")?.[1];
      setCurrent(route);
    }
  }, [location?.pathname]);

  useEffect(() => {
    fetch("https://backend.inkox.com/api/product-sub-categories")
      .then(response => response.json())
      .then(data => {
        const categoryMap = {};
        data.forEach(subcategory => {
          const categoryId = subcategory.category.id;
          if (!categoryMap[categoryId]) {
            categoryMap[categoryId] = {
              id: categoryId,
              name: subcategory.category.name,
              icon: subcategory.category.icon_path,
              subcategories: [],
            };
          }
          categoryMap[categoryId].subcategories.push(subcategory);
        });
        setCategories(Object.values(categoryMap));
      })
      .catch(error => console.error("Error fetching subcategories:", error));
  }, []);

  const onClick = (e) => {
    if (e.key === "logout") {
      removeFromLocalStorage("APP_TOKEN");
      window.location.reload();
    } else {
      if (e.key && e.key !== current) {
        setCurrent(e.key);
        navigate(`/${e.key}`);
      }
    }
  };

  const onClose = () => {
    setOpen(false);
  };

  const handleSidebar = () => {
    setOpen(true);
  };

  const handleOpen = () => {
    handleGetPriceClick();
  }
  return (
    <header className="header">
      
      <div className="main-header-tool">
        <div className="containers">
<Row gutter={[40, 20]} justify="space-between" align="middle" className="tool-header-cls">
  {/* Left side: Burger menu and logo */}
  <Col xs={16} md={16} className="tool-headercls main-logomenu" style={{ display: 'flex', alignItems: 'center' }}>
    <img 
      src={bumberger} 
      alt="Menu" 
      className="sideBarIcon" 
      onClick={handleSidebar} 
      style={{ marginRight: "10px", cursor: 'pointer', width: "24px", height: "auto" }} // Adjust size as needed
    />
    <Link to={"/"} className="brand-logo tool-logo-brand">
      <img src={logo} alt="logo" />
    </Link>
  </Col>

  {/* Right side: ChatTalk - Hidden on mobile */}
  <Col xs={0} md={0} lg={8} style={{ textAlign: "right" }} className="tool-headercls chatTalkcls">
    <ChatTalk />
  </Col>
  <Col xs={8} md={8} lg={0} style={{ textAlign: "right" }} className="tool-headercls chatTalkcls">
   <button onClick={handleOpen} className="get-price-button get-price-buttonheader ">
   <span>
                  {/* <img src={envelope} /> */}
                </span>
                <span>Get Price</span>
   </button>
  </Col>

</Row>




        </div>
      </div>
      

      <Drawer
        placement="left"
        onClose={onClose}
        open={open}
        className="header-sidebar"
      >
        
        <Menu
          onClick={onClick}
          selectedKeys={[current]}
          mode="inline"
          items={items}
        />
        
      </Drawer>

    </header>

  );
};

export default HeaderTool;
