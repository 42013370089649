import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LayoutWrapper from '../layout/layoutWrapper/LayoutWrapper';
import './Blog.css';

const Loader = () => (
    <div className="loader-container loader-main-index">
        <div className="spinner"></div>
    </div>
);

const Blog = () => {
    const navigate = useNavigate();
    const [blogs, setBlogs] = useState([]);
    const [categories, setCategories] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Fetch blogs and categories simultaneously
        Promise.all([
            fetch("https://backend.inkox.com/api/blogs").then(response => response.json()),
            fetch("https://backend.inkox.com/api/blogs-catg").then(response => response.json())
        ])
        .then(([blogsData, categoriesData]) => {
            setBlogs(blogsData);
            setCategories(categoriesData);
        })
        .catch(error => console.error("Error fetching data:", error))
        .finally(() => setIsLoading(false)); // Stop loading once data is fetched
    }, []);

    // Function to truncate description to 50 characters
    const truncateDescription = (htmlDescription) => {
        const textContent = new DOMParser().parseFromString(htmlDescription, "text/html").body.textContent || "";
        return textContent.length > 50 ? textContent.substring(0, 50) + "..." : textContent;
    };

    // Group blogs by category
    const groupedBlogs = categories.map(category => ({
        ...category,
        blogs: blogs.filter(blog => blog.catg_id === category.id),
    }));

    const handleBlogClick = (id) => {
        navigate(`/blogs/${id}`);
    };

    return (
        <LayoutWrapper>
            <div className='main-blog-div'>
                {/* Show loader while data is being fetched */}
                {isLoading ? (
                    <Loader />
                ) : (
                    groupedBlogs.map(category => (
                        <div key={category.id} className='main-blog-page'>
                            <h2 className="blog-category">{category.catg_name}</h2>
                            <div className="blog-container container" data-count={category.blogs.length}>
                                {category.blogs.length > 0 ? (
                                    category.blogs.map((blog) => (
                                        <div key={blog.id} className="blog-card" onClick={() => handleBlogClick(blog.id)}>
                                            <img 
                                                src={`https://backend.inkox.com/storage/${blog.image}`} 
                                                alt={blog.title} 
                                                className="blog-image" 
                                            />
                                            <div className="blog-content blogcontent-two">
                                                <h3 className="blog-title">{blog.title}</h3>
                                                <p className="blog-description">{truncateDescription(blog.description)}</p>
                                                <button className="read-more-blog">Continue Reading</button>
                                                <div className="blog-author">
                                                    <div className="author-details">
                                                        <span className="post-date">{new Date(blog.date).toDateString()}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <p>No blogs available in this category.</p>
                                )}
                            </div>
                        </div>
                    ))
                )}
            </div>
        </LayoutWrapper>
    );
};

export default Blog;
